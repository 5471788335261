<template>
  <base-table
    v-bind="$attrs"
    :cols="cols"
    :rows="rows"
    :lazy="true"
    :default-sort-order="-1"
    @row-click="rowClick"
  >
    <template #account="{ data }">
      <div>
        <div>
          {{ data.account }}
        </div>
        <div
          v-if="data.nickname"
          v-tooltip.top="data.nickname.length > 5 ? data.nickname : ''"
          class="text-sm color-zinc-400"
        >
          {{ `${data.nickname.slice(0, 5)}${data.nickname.length > 5 ? '...' : ''}` }}
        </div>
      </div>
    </template>
    <template #deposit_cash="{ data }">
      <base-cash :cash="data.deposit_cash" />
    </template>
    <template #withdraw_cash="{ data }">
      <div class="flex-row-center">
        <base-cash
          :cash="data.withdraw_cash"
          :negative="true"
        />
        <base-link-icon @click="() => click(data)" />
      </div>
    </template>
  </base-table>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { formatDate } from '@/utils/format'
import { PlayerStatusColor, PlayerStatusName, LayerName, NextLayer } from '@/constants/StatusMapping'
import { AccountSearchType, TransactionRes, AgentType } from '@golden/gdk-agent-next'
import { DataTableRowClickEvent } from 'primevue/datatable'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'
import { TableColType } from '@/utils/TableHelper'

export default defineComponent({
  name: 'TransactionRecordTable',
  props: {
    rows: {
      type: Array as PropType<TransactionRes[]>,
      default: () => ([])
    },
    layer: {
      type: Number as PropType<AgentType>,
      required: true
    }
  },
  emits: ['click'],
  setup (props, context) {
    const { t } = useI18n()
    const cols = computed<TableColType[]>(() => [
      {
        field: 'account',
        header: t(LayerName[NextLayer[props.layer]]),
        style: { 'min-width': '80px', height: '56px' }
      },
      {
        field: 'deposit_cash',
        header: t('common.depositCash'),
        align: 'end',
        sortable: true
      },
      {
        field: 'withdraw_cash',
        header: t('common.withdrawCash'),
        align: 'end',
        sortable: true,
        style: { 'min-width': '160px' }
      }
    ]
    )

    const { isDesktop } = storeToRefs(useMediaQueryStore())
    const rowClick = (event: DataTableRowClickEvent) => {
      if (isDesktop.value) return
      click(event.data)
    }

    const click = (data: unknown) => {
      context.emit('click', data)
    }

    return {
      t,
      cols,
      formatDate,
      PlayerStatusName,
      PlayerStatusColor,
      click,
      rowClick,
      AccountSearchType
    }
  }
})
</script>
