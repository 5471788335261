<template>
  <div class="py-2">
    <prime-textarea
      v-model="dialogRef.data.data"
      :placeholder="t('placeholder.input')"
      :auto-resize="true"
      :maxlength="100"
      :rows="5"
      :cols="30"
      class="w-full"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'MemoDialog',
  setup () {
    const dialogRef = inject('dialogRef') as Ref<{ data: { header: string, disabled: Ref<boolean>, data: string } }>
    const { t } = useI18n()
    dialogRef.value.data.header = `${t('common.set')}${t('common.memo')}`

    return {
      t,
      dialogRef
    }
  }
})
</script>
