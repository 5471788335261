<template>
  <span :class="cashClass">
    {{ formatted }}
  </span>
</template>

<script lang="ts">
import { formatCurrency, formatCurrencyColor, formatRoundDown } from '@/utils/format'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'BaseCash',
  props: {
    cash: {
      type: [String, Number, null] as PropType<string | number | null>,
      required: true
    },
    format: {
      type: String as PropType<'currency' | 'decimal' | 'usdt'>,
      default: 'decimal'
    },
    noColor: {
      type: Boolean,
      default: false
    },
    negative: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()
    const cash = computed(() => {
      const value = Number(props.cash ?? 0)
      if (props.negative && value > 0) return value * -1
      return value
    })

    const cashClass = computed(() => {
      if (props.noColor) return
      const result = formatRoundDown(cash.value, props.format)
      return formatCurrencyColor(result)
    })
    const formatted = computed(() => {
      if (props.cash === null) return '-'
      return formatCurrency(cash.value, { style: props.format })
    })

    return {
      t,
      cashClass,
      formatted
    }
  }
})
</script>
