<template>
  <div
    :class="$style['base-icon']"
    v-bind="$attrs"
    :style="sizeStyle"
  >
    <svg
      :style="sizeStyle"
    >
      <use
        :xlink:href="`#${name}`"
        :href="`#${name}`"
      />
    </svg>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'BaseIcon',
  props: {
    name: {
      required: true,
      type: String
    },
    size: {
      default: 24,
      type: Number
    }
  },
  setup (props) {
    const sizeStyle = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`
    }))

    return {
      sizeStyle
    }
  }
})
</script>

<style lang="scss" module>
.base-icon {
  fill: currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
