<template>
  <div
    v-show="!hidden"
    class="md:hidden shadow shadow-truegray z-5"
    style="--un-shadow:0 0 10px var(--un-shadow-color, rgba(0,0,0,0.1))"
  >
    <nav
      id="nav-tabs"
      class="h-[var(--mobile-nav-tabs-height)] py-[var(--mobile-padding-y)] mb-[var(--safe-area-inset-bottom)] px-4"
    >
      <ul class="flex-row-between list-none m-0 p-0 h-full">
        <li
          v-for="item in nav"
          :key="item.key"
          class="item flex-1"
        >
          <router-link
            v-slot="{ navigate, isActive }"
            custom
            :to="{ name: item.routeName ?? undefined }"
          >
            <a
              :style="isActive ? { color: 'var(--primary-color)' } : undefined"
              class="inline-flex flex-col w-full decoration-none text-center color-black text-3.5"
              :class="{ active: isActive, 'fw-bold': isActive }"
              @click="navigate"
            >
              <div class="relative">
                <prime-badge
                  v-if="item.unread?.value"
                  value="new"
                  class="absolute right--2 top--4"
                />
                <prime-avatar class="mb-1 h-6 w-6 bg-transparent">
                  <i
                    v-if="item.icon"
                    :class="item.icon"
                    class="p-avatar-icon important-text-6"
                  />
                  <i
                    v-if="item.activeIcon"
                    :class="item.activeIcon"
                    class="p-avatar-icon important-text-6 hidden"
                  />
                  <span
                    v-if="item.name"
                    class="p-avatar-text text-6"
                  >
                    {{ item.name }}
                  </span>
                </prime-avatar>
              </div>
              <span class="text-3">{{ item.label }}</span>
            </a>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNav } from '@/functions/useNav'
import { useCheckPermission } from '@/functions/useCheckPermission'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { AgentType, Permissions } from '@golden/gdk-agent-next'

export default defineComponent({
  name: 'MobileNavTabs',
  setup () {
    const route = useRoute()
    const { t } = useI18n()
    const { me } = storeToRefs(useAgentStore())
    const account = computed(() => {
      if (me.value?.is_assistant) return me.value.parent_account.slice(0, 2)
      if (me.value?.type === AgentType.EMPEROR) return me.value.account.slice(0, 1)
      return me.value?.account.slice(0, 2)
    })
    const promoteUrlAccessible = useCheckPermission([Permissions.PROMOTE_URL])
    const navConfigs = computed(() => [
      {
        key: 'home',
        icon: 'i-ri-home-4-line',
        activeIcon: 'i-ri-home-4-fill',
        label: t('page.home'),
        routeName: RouteNameType.HOME
      },
      {
        key: 'users',
        icon: 'i-ri-group-line',
        activeIcon: 'i-ri-group-fill',
        label: t('page.users'),
        routeName: RouteNameType.USERS,
        children: [RouteNameType.PLAYERS, RouteNameType.AGENTS, RouteNameType.SUB_ACCOUNT]
      },
      {
        key: 'promote',
        icon: 'i-ri-share-line',
        activeIcon: 'i-ri-share-fill',
        label: t('page.promote'),
        routeName: RouteNameType.PROMOTE,
        children: [RouteNameType.PROMOTE_URL, RouteNameType.PROMOTE_ACTIVITIES],
        unread: computed(() => !me.value?.is_domain_read && route.name !== 'promote-url' && promoteUrlAccessible.value ? 1 : 0)
      },
      {
        key: 'report',
        icon: 'i-ri-table-line',
        activeIcon: 'i-ri-table-fill',
        label: t('page.report'),
        routeName: RouteNameType.REPORT,
        children: [RouteNameType.COMMISSION, RouteNameType.BET, RouteNameType.ACTIVITY, RouteNameType.TRANSACTION]
      },
      {
        key: 'me',
        icon: undefined,
        name: account.value,
        label: t('page.me'),
        routeName: RouteNameType.ME
      }
    ])
    const { nav } = useNav(navConfigs)

    const hidden = computed(() => route.meta.hiddenTabs)

    return {
      nav,
      route,
      hidden
    }
  }
})
</script>

<style lang="scss" scoped>
.item {
  .active .p-avatar-icon, &:hover .p-avatar-icon, &:active .p-avatar-icon {
    @apply first:hidden inline-block;
  }
  .p-avatar-text {
    font-family: DIN Alternate, Roboto, -apple-system, BlinkMacSystemFont, Arial, Noto Sans, "Microsoft YaHei", sans-serif;
  }
}
</style>
