import { ref, onActivated, ComputedRef, computed } from 'vue'
import PrimeChart from 'primevue/chart'
import type { ChartOptions, ChartData, Chart, ChartConfiguration } from 'chart.js'
import i18n from '@/i18n'
import { addMonths, lastDayOfMonth } from 'date-fns'
import { range } from '@golden/utils'
import { getTaipeiNowTime } from '@/utils/TimeHelper'

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer?.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.margin = '0'
    listContainer.style.padding = '0'

    legendContainer?.appendChild(listContainer)
  }

  return listContainer
}

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate (chart: Chart, _: unknown, options: { containerID: string }) {
    const ul = getOrCreateLegendList(options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }
    if (chart.options.plugins?.legend?.labels?.generateLabels) {
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart)

      items.forEach(item => {
        const li = document.createElement('li')
        li.style.alignItems = 'center'
        li.style.cursor = 'pointer'
        li.style.display = 'flex'
        li.style.flexDirection = 'row'
        li.style.marginLeft = '13px'

        li.onclick = () => {
          const { type } = chart.config as ChartConfiguration
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index as number)
          } else {
            chart.setDatasetVisibility(item.datasetIndex as number, !chart.isDatasetVisible(item.datasetIndex as number))
          }
          chart.update()
        }

        // Color box
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.fillStyle as string
        boxSpan.style.borderColor = item.strokeStyle as string
        boxSpan.style.borderWidth = `${item.lineWidth ?? 1}px`
        boxSpan.style.display = 'inline-block'
        boxSpan.style.height = '5px'
        boxSpan.style.marginRight = '4px'
        boxSpan.style.width = '10px'

        // Text
        const textContainer = document.createElement('p')
        textContainer.style.color = item.fontColor as string
        textContainer.style.margin = '0'
        textContainer.style.padding = '0'
        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
        textContainer.style.fontSize = '13px'

        const text = document.createTextNode(item.text)
        textContainer.appendChild(text)

        li.appendChild(boxSpan)
        li.appendChild(textContainer)
        ul.appendChild(li)
      })
    }
  }
}

export const useLineChart = (
  legendId: string,
  customData: ComputedRef<Partial<ChartData>>,
  customOptions?: ComputedRef<Partial<ChartOptions>>
) => {
  const lastDate = Math.max(lastDayOfMonth(getTaipeiNowTime()).getDate(), lastDayOfMonth(addMonths(getTaipeiNowTime(), -1)).getDate())
  const defaultData = {
    labels: range(1, lastDate + 1).map((number) => i18n.global.t('common.dateCount', { number })),
    datasets: []
  }
  const defaultOptions = {
    plugins: {
      legend: {
        display: false
      },
      htmlLegend: {
        containerID: legendId
      },
      tooltip: {
        intersect: false
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
          autoSkip: false,
          callback: function (value: number, index: number) {
            const number = index + 1
            const that = this as unknown as { getLabelForValue: (value: number) => string }
            if ([1, lastDate].includes(number)) return that.getLabelForValue(value)
            if (lastDate === 31 && number === 30) return ''
            if (number % 5) return ''
            return that.getLabelForValue(value)
          }
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        ticks: {
          color: '#495057',
          maxTicksLimit: 7,
          stepSize: 1
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  }

  const options = computed(() => ({
    ...defaultOptions,
    ...customOptions ? customOptions.value : {}
  }))

  const data = computed(() => ({
    ...defaultData,
    ...customData.value
  }))

  return {
    data,
    options,
    plugins: [
      htmlLegendPlugin
    ],
    type: 'line'
  }
}

export const useBarChart = (
  legendId: string,
  customData: ComputedRef<Partial<ChartData>>,
  customOptions?: ComputedRef<Partial<ChartOptions>>
) => {
  const defaultData = {
    labels: [],
    datasets: []
  }

  const defaultOptions = {
    plugins: {
      legend: {
        display: false
      },
      htmlLegend: {
        containerID: legendId
      },
      tooltip: {
        intersect: false
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
          autoSkip: false
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        ticks: {
          color: '#495057',
          maxTicksLimit: 7,
          stepSize: 1
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  }
  const data = computed(() => ({
    ...defaultData,
    ...customData.value
  }))

  const options = computed(() => ({
    ...defaultOptions,
    ...customOptions ? customOptions.value : {}
  }))

  return {
    data,
    options,
    plugins: [
      htmlLegendPlugin
    ],
    type: 'bar'
  }
}

export const useDoughnutChart = (
  customData: ComputedRef<Partial<ChartData>>,
  customOptions?: ComputedRef<Partial<ChartOptions>>) => {
  const defaultData = {
    datasets: []
  }
  const defaultOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        formatter: () => {
          return null
        }
      }
    }
  }

  const data = computed(() => ({
    ...defaultData,
    ...customData.value
  }))

  const options = computed(() => ({
    ...defaultOptions,
    ...customOptions ? customOptions.value : {}
  }))

  const chart = ref<InstanceType<typeof PrimeChart>>()

  onActivated(() => {
    chart.value?.reinit()
  })

  return {
    data,
    options,
    type: 'doughnut',
    chart
  }
}
