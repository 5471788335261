import { defineStore } from 'pinia'

export interface State {
  breadCrumb: Array<{ label: string, to?: string }>
}

export const useLayoutStore = defineStore('layout', {
  state: (): State => {
    return {
      breadCrumb: []
    }
  },
  actions: {
    setBreadCrumb (value: Array<{ label: string, to?: string }>) {
      this.breadCrumb = value
    }
  }
}
)
