export const BreakPoints = {
  // mobile
  sm: 576,
  // tablet
  md: 768,
  // desktop
  lg: 992,
  // large screen
  xl: 1200
}
