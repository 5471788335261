import { useAgentStore } from '@/stores/agent'
import { Permissions } from '@golden/gdk-agent-next'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
export const useCheckPermission = (permissions: Permissions[], type: 'some' | 'every' = 'every') => {
  const { me } = storeToRefs(useAgentStore())

  const accessible = computed(() => {
    return permissions[type]((permission) => me.value?.permissions?.includes(permission))
  })

  return accessible
}
