import { ref } from 'vue'
import i18n from '../i18n'
import { urlHelper } from '../utils/urlHelper'
import { openWindow } from '@golden/shared'

export function useCustomer (): { openCustomer: () => void } {
  const { locale } = i18n.global
  const loading = ref(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let win: Window | null = null

  const openCustomer = () => {
    if (loading.value) return
    loading.value = true

    const open = () => {
      const url = `${urlHelper.get('bf')}?lng=${locale.value}`

      win = openWindow(`_golden_customer${Number(new Date())}`, url, 800, 900)
      loading.value = false
    }

    void open()
  }

  return { openCustomer }
}
