<template>
  <div
    v-if="agents.length > 1"
    class="agents-breadcrumb"
  >
    <prime-breadcrumb
      :home="home"
      :model="model"
    >
      <template #item="{item}">
        <a
          v-if="!item.active"
          class="link"
          @click="() => click(item)"
        >
          {{ item.account }}
        </a>
        <template v-else>
          {{ item.account }}
        </template>
      </template>
    </prime-breadcrumb>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { AgentType } from '@golden/gdk-agent-next'

export default defineComponent({
  name: 'AgentsBreadCrumb',
  props: {
    agents: {
      type: Array as PropType<Array<{ account: string, id: number, layer?: AgentType, index: number }>>,
      default: () => ([])
    }
  },
  emits: ['click'],
  setup (props, context) {
    const model = computed(() => {
      return props.agents.slice(1).map((item, index, array) => ({ ...item, active: index === array.length - 1 }))
    })

    const home = computed(() => {
      return props.agents[0] ?? {}
    })

    const click = (data: unknown) => {
      context.emit('click', data)
    }

    return {
      home,
      model,
      click
    }
  }
})
</script>

<style lang="scss" scoped>
.agents-breadcrumb {
  .p-breadcrumb {
    @apply p-0 bg-transparent border-0 text-4;

    :deep(.pi.pi-chevron-right) {
      @apply i-ri-arrow-right-s-line text-3;
    }
  }
}
</style>
