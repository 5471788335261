<template>
  <layout-content
    :loading="loading"
    @reload="reload"
    @scroll="scroll"
  >
    <div class="flex flex-col h-full after-content-none after-pt-5rem after--mb-5rem">
      <div class="overflow-x-auto overflow-y-hidden flex-shrink-0 pb-3">
        <div class="flex items-center w-auto">
          <div class="flex-shrink-0">
            <base-date-button
              v-model="date"
              :max-date="getTaipeiNowTime()"
              :label="t('common.registerDate')"
            />
          </div>
          <div class="flex-shrink-0 ml-2">
            <players-filter-button
              v-model="filter"
            />
          </div>
          <div class="flex-shrink-0 ml-2">
            <player-search
              v-model:account="account"
              @click="handleAccountSearch"
              @clear="handleAccountClear"
            />
          </div>
          <div class="flex-shrink-0 ml-12px color-blueGray-7 hidden md:block">
            <span>{{ t('common.playerRigesterCount') }} {{ lastRes?.register_count ?? '' }}</span>
            <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
            <span class="mx-8px color-zinc-4">|</span>
            <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
            <span>{{ t('common.playerDepositCount') }} {{ lastRes?.deposit_count ?? '' }}</span>
          </div>
        </div>
      </div>
      <players-summary-card
        v-if="loading || rows.length"
        class="w-full mb-3"
        :loading="isFirstLoad"
        :raw-data="lastRes"
      />
      <base-no-data v-if="!rows.length && !loading" />
      <base-card
        v-else
      >
        <players-table
          v-model:sort-by="sortBy"
          v-model:order="order"
          :rows="rows"
          :loading="loading"
          :is-first-load="isFirstLoad"
          :finished="finished"
          @update="reload"
        />
      </base-card>
    </div>
  </layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQueryStore, State } from '@/stores/query'
import { PlayerRankType, PlayersReq, Sort, PaginationReq, PlayerStatusType, PlayersSearchRes } from '@golden/gdk-agent-next'
import { usePagination } from '@golden/shared-vue'
import gdk from '@/gdk'
import { tap } from 'rxjs'
import { getTaipeiNowTime } from '@/utils/TimeHelper'

export default defineComponent({
  name: 'PlayersView',
  setup () {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useQueryStore()

    // query logic
    const query = ref<State['player']>({ ...route.query })
    const sortBy = ref<'register_at' | 'last_login_at'>('register_at')
    const order = ref<'asc' | 'desc'>('desc')
    const filter = computed({
      get () {
        return {
          ...query.value.ranks ? { ranks: Array.isArray(query.value.ranks) ? query.value.ranks : [query.value.ranks] as PlayerRankType[] } : {},
          ...query.value.status ? { status: Array.isArray(query.value.status) ? [...query.value.status].map(item => Number(item)) as PlayerStatusType[] : [Number(query.value.status)] as PlayerStatusType[] } : {},
          ...query.value.is_deposit ? { is_deposit: Array.isArray(query.value.is_deposit) ? [...query.value.is_deposit].map(item => Number(item)) as Array<0 | 1> : [Number(query.value.is_deposit)] as Array<0 | 1> } : {},
          ...query.value.not_login_days ? { not_login_days: query.value.not_login_days } : {}
        }
      },
      set (payload: { ranks?: PlayerRankType[], status?: PlayerStatusType[], is_deposit?: Array<0 | 1>, not_login_days?: number }) {
        query.value = {
          ...query.value,
          ...payload
        }

        void router.replace({
          ...route,
          query: query.value
        })
      }
    })
    const date = computed({
      get () {
        if (query.value.register_start_at && query.value.register_end_at) {
          return [new Date(Number(query.value.register_start_at)), new Date(Number(query.value.register_end_at))]
        }
        return undefined
      },
      set (value: Date[] | undefined) {
        query.value = {
          ...query.value,
          register_start_at: value ? value[0].getTime() : undefined,
          register_end_at: value ? value[1].getTime() : undefined

        }

        void router.replace({ ...route, query: query.value })
      }
    })

    const { rows, loading, lastRes, reload, finished, isFirstLoad, scroll } = usePagination(
      'rows',
      computed(() => ({
        ...query.value,
        ...filter.value,
        sort_by: sortBy.value,
        order: order.value
      })),
      (params) => gdk.user.getPlayers(params as PlayersReq<number> & Sort & PaginationReq).pipe(tap(() => { store.setPlayer({ ...query.value, ...{ account: account.value } }) }))
    )
    const account = computed({
      get () {
        return query.value.account
      },
      set (value: string | undefined) {
        query.value = {
          ...query.value,
          account: value
        }
      }
    })
    const handleAccountSearch = (item: PlayersSearchRes) => {
      query.value = {
        ...query.value,
        account: item.account,
        agent_id: item.id
      }
      void router.replace({ ...route, query: query.value })
    }

    const handleAccountClear = () => {
      query.value = {
        ...query.value,
        account: undefined,
        agent_id: undefined
      }
      void router.replace({ ...route, query: query.value })
    }

    return {
      filter,
      date,
      rows,
      lastRes,
      loading,
      isFirstLoad,
      sortBy,
      order,
      finished,
      account,
      t,
      scroll,
      reload,
      handleAccountSearch,
      handleAccountClear,
      getTaipeiNowTime
    }
  }
})
</script>
