<template>
  <div
    class="py-4 flex-row-between md:justify-start border-truegray-200 border-b-solid border-b last:border-b-0"
    @click="click"
  >
    <span class="label w-120px mr-6 flex-shrink-0 lh-normal break-keep my-auto">
      <slot name="label">
        {{ label }}
      </slot>
    </span>
    <span
      class="value relative flex-1 flex-row-end min-w-0 flex-wrap md:justify-start"
      :style="{ width }"
      @click="valueClick"
    >
      <base-skeleton
        :loading="loading"
        :options="skeltionOptions"
      >
        <slot />
        <span
          v-if="$slots.tip"
          class="text-sm truncate w-full mt-2px mr-30px text-right md:text-left"
          :class="{ 'p-error': error }"
        >
          <slot name="tip" />
        </span>
      </base-skeleton>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'BaseListItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    showBorder: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    skeltionOptions: {
      type: Object,
      default: () => ({ height: '20px', width: '100px' })
    },
    width: {
      type: String,
      default: undefined
    }
  },
  emits: ['click', 'value-click'],
  setup (_, context) {
    const { isMobile } = storeToRefs(useMediaQueryStore())
    return {
      click: () => {
        if (!isMobile.value) return
        context.emit('click')
      },
      valueClick: () => {
        context.emit('value-click')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.p-component:not(button)) {
  @apply disabled:op-100;
}
:deep(.p-inputtext) {
  @apply important-p-0;
}
</style>
