<template>
  <div class="py-2">
    <div class="py-7px">
      <span>
        {{ t('common.account') }}：
      </span>
      <span>
        {{ account }}
      </span>
    </div>
    <div class="py-7px flex-row-start">
      <span>
        {{ t('common.type') }}：
      </span>
      <div class="flex-1">
        <prime-dropdown
          v-model="dialogRef.data.dialogData.export_type"
          class="important-px-0 w-full"
          :options="typeOptions"
          option-label="name"
          option-value="value"
          :placeholder="t('placeholder.select')"
        />
      </div>
    </div>
    <div class="color-red-600 pb-7px mt-1px pl-42px">
      {{ tip }}
    </div>
    <div class="py-7px">
      <span>
        {{ t('common.time') }}：
      </span>
      <span>
        {{ dateLabel }} {{ date }}
      </span>
    </div>
    <div class="py-7px">
      <span>
        {{ t('common.orderStatus') }}：
      </span>
      <span>
        {{ status }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { formatTaipeiTime } from '@/utils/TimeHelper'
import { AgentType, TransactionStatus } from '@golden/gdk-agent-next'
import { defineComponent, computed, inject, Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TransactionExportDialog',
  setup () {
    const { t } = useI18n()
    const dialogRef = inject('dialogRef') as Ref<{ data: { header: string, disabled: Ref<boolean>, dialogData: { account: string, current_layer: AgentType | -1, export_type: 'all' | 'deposit' | 'withdraw', status: TransactionStatus[], search_type: 'created_at' | 'finished_at', start_at: number, end_at: number } } }>
    dialogRef.value.data.header = `${t('common.confirm')}${t('common.export')}`

    const account = computed(() => dialogRef.value.data.dialogData.account)
    const typeOptions = computed(() => ([
      {
        name: t('common.all'),
        value: 'all'
      },
      {
        name: t('common.deposit'),
        value: 'deposit'
      },
      {
        name: t('common.withdraw'),
        value: 'withdraw'
      }
    ]
    ))

    const dateLabel = computed(() => {
      if (dialogRef.value.data.dialogData.search_type === 'finished_at') return t('common.confirmAt')
      else if (dialogRef.value.data.dialogData.search_type === 'created_at') return t('common.applyAt')
      return ''
    })
    const date = computed(() => `${formatTaipeiTime(new Date(Number(dialogRef.value.data.dialogData.start_at)), 'MM/dd')} - ${formatTaipeiTime(new Date(Number(dialogRef.value.data.dialogData.end_at)), 'MM/dd')}`)
    const status = computed(() => {
      const status = dialogRef.value.data.dialogData.status
      if (status?.length === 3 || status?.length === 0 || !status) return t('common.all')
      const mapping = {
        [TransactionStatus.IN_PROGRESS]: t('common.inProgress'),
        [TransactionStatus.SUCCESSFUL]: t('common.success'),
        [TransactionStatus.FAIL]: t('common.failed')
      }

      return status.map(item => mapping[item]).join('、')
    })

    const tip = computed(() => t(dialogRef.value.data.dialogData.current_layer === -1 ? 'message.export.transactionPlayerTip' : 'message.export.transactionAgentTip', { account: account.value, type: typeOptions.value.find(item => item.value === dialogRef.value.data.dialogData.export_type)?.name ?? '' }))

    return {
      t,
      dialogRef,
      account,
      typeOptions,
      dateLabel,
      date,
      status,
      tip
    }
  }
})
</script>
