<template>
  <base-card class="p-5">
    <div class="mb-4 flex-row-between">
      <h4 class="m-0 lh-normal">
        {{ t('leaderboard.title') }}
      </h4>
      <div
        :id="id"
        class="flex-row-center color-#3B82F6 cursor-pointer"
        @click="detailClick"
      >
        {{ t('common.detailInfo') }}
        <base-link-icon />
      </div>
    </div>
    <div
      class="relative"
      :class="{ 'pt-50%': loading }"
    >
      <div :class="{ 'absolute-full': loading, 'overflow-x-auto': true }">
        <base-skeleton
          :loading="loading"
          :options="{ size: '100%' }"
        >
          <prime-scroll-panel
            v-if="rows.length"
            class="h-[calc(15vw)] min-h-50 w-full"
          >
            <base-table
              v-bind="$attrs"
              :cols="cols"
              :rows="rows"
              :lazy="true"
              @row-click="rowClick"
            >
              <template #ranking="{ data }">
                <span>{{ data.ranking }}</span>
              </template>
              <template #account="{ data }">
                <router-link
                  v-if="isDesktop"
                  class="link"
                  :to="`/users/players/${data.user_id}`"
                >
                  {{ data.account }}
                </router-link>
                <span v-else>{{ data.account }}</span>
              </template>
              <template #revenue="{ data }">
                <base-cash :cash="data.revenue" />
              </template>
            </base-table>
          </prime-scroll-panel>
          <div
            v-else
            class="h-[calc(15vw)] min-h-50 w-full"
          >
            <base-no-data />
          </div>
        </base-skeleton>
      </div>
    </div>
    <template
      v-if="rawData"
      #footer
    >
      <prime-scroll-panel class="h-9 w-full">
        <div class="flex w-full">
          <div
            v-for="(button, index) in labels"
            :key="index"
            class="whitespace-nowrap mr-2 last:mr-0"
          >
            <prime-button
              class="p-button-sm p-button-rounded important-py-2 important-px-4"
              :class="{ 'p-button-outlined': button.value !== current }"
              @click="() => { current = button.value }"
            >
              {{ button.label }}
            </prime-button>
          </div>
        </div>
      </prime-scroll-panel>
    </template>
  </base-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { HomeRes, LeaderboardData } from '@golden/gdk-agent-next'
import { uniqueId } from '@golden/utils'
import { storeToRefs } from 'pinia'
import { useMediaQueryStore } from '@/stores/screen'
import { DataTableRowClickEvent } from 'primevue/datatable'
import { useRouter } from 'vue-router'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { TableColType } from '@/utils/TableHelper'

type OptionType = 'positive' | 'negative'

export default defineComponent({
  name: 'LeaderboardCard',
  props: {
    rawData: {
      type: Object as PropType<HomeRes>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()

    const id = uniqueId('home-leaderboard-')
    const { isDesktop, isMobile } = storeToRefs(useMediaQueryStore())
    const router = useRouter()
    const current = ref<OptionType>('negative')

    const cols = computed<TableColType[]>(() => [
      {
        field: 'ranking',
        header: t('common.ranking'),
        style: { 'min-width': '55px' }
      },
      {
        field: 'account',
        header: t('common.player'),
        style: { 'min-width': '55px' }
      },
      {
        field: 'revenue',
        header: `${t('common.thisMonthPluslastMonth')}${t('common.total', { item: t('common.revenue') })}`,
        align: 'end',
        style: { 'min-width': '150px' }
      }
    ])

    const rowClick = (event: DataTableRowClickEvent) => {
      if (isMobile.value) {
        void router.push(`/users/players/${(event.data as { user_id: string }).user_id}`)
      }
    }

    const detailClick = () => {
      void router.push({
        name: RouteNameType.LEADERBOARD,
        query: { order_by: (current.value === 'positive' ? 'desc' : 'asc') }
      })
    }

    const rows = computed(() => {
      let result: LeaderboardData[]
      if (!props.rawData.leaderboard) {
        result = []
      } else if (current.value === 'negative') {
        result = props.rawData.leaderboard.negative
      } else {
        result = props.rawData.leaderboard.positive
      }
      return result.map((item, index) => ({
        ranking: index + 1,
        ...item
      }))
    })

    const labels = computed(() => [
      {
        label: t('leaderboard.negative'),
        value: 'negative'
      },
      {
        label: t('leaderboard.positive'),
        value: 'positive'
      }
    ] as Array<{ label: string, value: OptionType }>)

    return {
      id,
      t,
      cols,
      rows,
      rowClick,
      detailClick,
      labels,
      current,
      isDesktop
    }
  }
})
</script>
