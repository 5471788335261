<template>
  <div class="-m-2">
    <div class="flex-row-start flex-wrap">
      <div
        v-for="item in gamesOptions"
        :key="item.id"
        :class="{ 'p-button-outlined': !modelValue.includes(item.id) }"
        class="w-33.3333% px-2 pt-2"
      >
        <base-skeleton
          :loading="loading"
          :options="{ height: '38px', borderRadius: '50px' }"
        >
          <prime-button
            class="p-button-rounded p-2 md:px-3 w-full"
            :class="{ 'p-button-outlined': !modelValue.includes(item.id) }"
            @click="() => onClick(item.id)"
          >
            <base-icon
              :name="item?.instance?.platform.walletIconSvgName ?? ''"
              :size="20"
              class="mr-1 md:mr-2 w-20px bg-[#BA9F72] rd-full color-white"
            />
            <div class="text-xs md:text-sm">
              {{ item?.instance?.name ?? '' }}
            </div>
          </prime-button>
        </base-skeleton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue'
import { useGames } from '@/functions/useGames'
import { range } from '@golden/utils'

const skeletonGame = range(0, 9).map((id) => ({ id, icon: '', name: '', instance: null }))
export default defineComponent({
  name: 'BaseGamesSelect',
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const { games, loading } = useGames()
    const gamesOptions = computed(() => loading.value ? skeletonGame : games.value.filter((el) => el.is_active))

    const onClick = (id: number) => {
      if (loading.value) return
      const index = props.modelValue.findIndex(item => item === id)
      const newValue = [...props.modelValue]
      if (index === -1) {
        newValue.push(id)
      } else {
        newValue.splice(index, 1)
      }
      context.emit('update:modelValue', newValue)
    }

    return {
      gamesOptions,
      loading,
      onClick
    }
  }
})
</script>
