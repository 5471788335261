import type { MenuItem } from 'primevue/menuitem'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { computed, ComputedRef, Ref, ref } from 'vue'
import { routeNameMapPermissions, RouteNameType } from '@/constants/routeNameMapPermission'
import { uniqueId } from '@golden/utils'
import { LocationQueryRaw } from 'vue-router'

export interface NavItem extends MenuItem {
  items?: NavItem[]
  routeName: RouteNameType | null
  query?: LocationQueryRaw
  unread?: Ref<number>
  label?: string
  replace?: boolean
}

export const useNav = (navConfigs: ComputedRef<NavItem[]>) => {
  const { me } = storeToRefs(useAgentStore())
  const recurseUnread = (items: NavItem[]): NavItem[] => {
    return items.map((el) => ({
      ...el,
      unread: ref(el.items ? el.items.reduce((pre, cur) => pre + (cur.unread?.value ?? 0), 0) : (el.unread?.value ?? 0)),
      ...(el.items ? { items: recurseUnread(el.items) } : {})
    }))
  }
  const recurseFilter = (func: (item: NavItem) => boolean) => (items: NavItem[]) => {
    const result = items.filter(func)
    return result.map((el) => {
      if (el.items) return ({ ...el, items: pipe(el.items, [recurseFilter(func)]) })
      return el
    })
  }
  const recurseMap = (func: (item: NavItem, index: number) => NavItem) => (items: NavItem[]) => {
    const result = items.map(func)
    return result.map((el) => {
      if (el.items) return ({ ...el, items: pipe(el.items, [recurseMap(func)]) })
      return el
    })
  }
  const pipe = (items: NavItem[], funcs: Array<(items: NavItem[]) => NavItem[]>) => {
    let result = items
    for (const func of funcs) {
      result = func(result)
    }
    return result
  }
  const nav = computed(() => {
    return pipe(navConfigs.value, [
      recurseFilter((el) => {
        if (!el.routeName) return true
        if (!me.value) return false
        const permissions = routeNameMapPermissions[el.routeName]
        if (!permissions.length) return true
        return routeNameMapPermissions[el.routeName].some((el) => me.value?.permissions.includes(el))
      }),
      recurseUnread,
      recurseMap((el) => ({
        ...el,
        to: el.routeName ? { name: el.routeName, query: el.query, replace: el.replace ?? false } : '',
        command: el.command,
        key: el.routeName ?? uniqueId('nav-')
      }))
    ])
  })

  return {
    nav
  }
}
