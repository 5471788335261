<template>
  <home-chart-card
    :id="id"
    v-model="current"
    :title="t('overview.register')"
    :labels="labels"
    :data="data"
    :options="options"
    :plugins="plugins"
    :type="type"
    @update:hidden="handleUpdateHidden"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { HomeRes } from '@golden/gdk-agent-next'
import { useBarChart } from '@/functions/useCommonChart'
import { uniqueId } from '@golden/utils'
import { LineColor } from '@/constants/ChartColor'
import { formatHomeChartValue } from '@/utils/format'

type OptionType = 'user_count' | 'cash'

export default defineComponent({
  name: 'RegisterCard',
  props: {
    rawData: {
      type: Object as PropType<HomeRes>,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()

    const id = uniqueId('home-register-')
    const { data, options, plugins, type } = useBarChart(id, computed(() => {
      const keys: Array<keyof HomeRes['this_month']['register_summary']> = current.value === 'user_count'
        ? ['user_count', 'deposit_user_count', 'bet_user_count']
        : ['first_deposit', 'effective_bet']
      const labels = current.value === 'user_count'
        ? [
            t('common.numberOfPeople', { prefix: t('common.register') }),
            t('common.numberOfPeople', { prefix: `${t('common.register')}${t('common.deposit')}` }),
            t('common.numberOfPeople', { prefix: `${t('common.register')}${t('common.bet')}` })
          ]
        : [
            t('common.firstDepositCash'),
            t('common.effectiveBet', { prefix: t('common.register') })
          ]

      return {
        labels,
        datasets: [
          {
            data: keys.map((el) => formatHomeChartValue(props.rawData.this_month.register_summary[el])),
            label: t('common.thisMonth')
          },
          {
            data: keys.map((el) => formatHomeChartValue(props.rawData.last_month.register_summary[el])),
            label: t('common.lastMonth')
          }
        ].map((el, i) => ({ ...el, backgroundColor: LineColor[i], hidden: hidden.value[i] }))
      }
    }))
    const current = ref<OptionType>('user_count')
    const hidden = ref([false, false])
    const handleUpdateHidden = (value: boolean[]) => { hidden.value = value }

    const labels = computed(() => [
      {
        label: t('common.numberOfPeople'),
        value: 'user_count'
      },
      {
        label: t('common.numberOfCash'),
        value: 'cash'
      }
    ] as Array<{ label: string, value: OptionType }>)

    return {
      id,
      t,
      labels,
      data,
      options,
      plugins,
      type,
      current,
      hidden,
      handleUpdateHidden
    }
  }
})
</script>
