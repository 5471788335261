import { ref, computed, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { AccountSearchRes, AccountSearchType, AgentType } from '@golden/gdk-agent-next'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import type { State } from '@/stores/query'

export const useReportDefaultQuery = () => {
  const { me } = storeToRefs(useAgentStore())
  const defaultQuery = computed(() => ({
    id: me.value?.is_assistant ? me.value.parent_id : me.value?.id,
    account: me.value?.is_assistant ? me.value.parent_account : me.value?.account,
    layer: me.value?.is_assistant ? me.value.parent_type : me.value?.type as AgentType,
    id_type: AccountSearchType.AGENT
  }))

  return defaultQuery
}

export const useReport = <T extends Exclude<keyof State, 'player'>>(queryKey?: T) => {
  const route = useRoute()
  const router = useRouter()
  const defaultAccountQuery = useReportDefaultQuery()
  const query = ref({ ...defaultAccountQuery.value, ...route.query }) as unknown as Ref<State[T]>

  const resetAgents = () => {
    return [
      {
        account: query.value?.account as string,
        id: query.value?.id as number,
        layer: query.value?.layer && Number(query.value?.layer) as AgentType | -1,
        index: 0
      }
    ]
  }
  const agents = ref(resetAgents())

  const currentAgent = computed(() => {
    const { id, account, layer } = agents.value[agents.value.length - 1]
    return { id, account, layer }
  })

  const goToAgent = (payload: { account: string, id: number, id_type: AccountSearchType, layer: AgentType, index: number }) => {
    agents.value = agents.value.slice(0, payload.index + 1)
  }

  const account = computed({
    get () {
      return query.value.account === defaultAccountQuery.value?.account ? '' : query.value.account as string
    },
    set (value: string | undefined) {
      query.value = {
        ...query.value,
        account: value
      }
    }
  })

  const handleAccountSearch = (item: AccountSearchRes & { id_type: AccountSearchType }) => {
    query.value = {
      ...query.value,
      id: item.id,
      account: item.account,
      layer: item.layer ?? -1,
      id_type: item.id_type
    }

    agents.value = resetAgents()
    void router.replace({ ...route, query: query.value })
  }

  const handleAccountClear = () => {
    query.value = {
      ...query.value,
      ...defaultAccountQuery.value
    }

    agents.value = resetAgents()
    void router.replace({ ...route, query: query.value })
  }

  return {
    defaultAccountQuery,
    query,
    [queryKey ?? 'query']: query,
    account,
    agents,
    currentAgent,
    goToAgent,
    handleAccountSearch,
    handleAccountClear
  }
}
