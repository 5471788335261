import { RouteNameType, routeNameMapPermissions } from './../constants/routeNameMapPermission'
import gdk, { getAgentMe } from '@/gdk'
import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized, LocationQueryRaw, RouteRecordName, RouterScrollBehavior } from 'vue-router'
import { LoginStatusStorage } from '@golden/shared-vue'
import { useQueryStore, State } from '@/stores/query'
import { storeToRefs } from 'pinia'
import { Permissions } from '@golden/gdk-agent-next'
import { useAgentStore } from '@/stores/agent'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hookNext = (next: NavigationGuardNext, callback: () => void): NavigationGuardNext => (...args: [to?: any]): void => {
  if (args.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    next(...args)
    return
  }
  callback()
}

export const pipe = (...guards: NavigationGuard[]): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (guards.length > 0) {
      void guards[0](to, from, hookNext(next, () => {
        void pipe(...guards.slice(1))(to, from, next)
      }))
      return
    }
    next()
  }

export const authGuard: NavigationGuard = (to, from, next) => {
  if (to.meta.noCheckAuth) {
    next()
    return
  }
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/no-unsafe-member-access
  if (to.meta.noCheckLogin || LoginStatusStorage.isCheck) {
    if (!to.meta.notLogin && !gdk.isLoginSubject.value) {
      next('/login')
      return
    }
    if (to.meta.notLogin && gdk.isLoginSubject.value) {
      next('/')
      return
    }
    next()
    return
  }
  next({ name: RouteNameType.PREPARE, replace: true, query: { to: to.fullPath } })
}

export const permissionGuard: NavigationGuard = async (to, from, next) => {
  if (!routeNameMapPermissions[to.name as RouteNameType]?.length) {
    next()
    return
  }
  try {
    const me = await getAgentMe()
    if (routeNameMapPermissions[to.name as RouteNameType].every((el) => me?.permissions.includes(el))) {
      next()
      return
    }
    if (me?.permissions.includes(Permissions.HOME)) {
      next({ name: RouteNameType.HOME })
    } else {
      next({ name: RouteNameType.ME })
    }
  } catch (e) {
    next({ name: RouteNameType.ME })
  }
}

export const assistantGuard: NavigationGuard = async (to, from, next) => {
  try {
    const me = await getAgentMe()
    if (me?.is_assistant) {
      next({ name: RouteNameType.ME })
      return
    }
    next()
  } catch (e) {
    next({ name: RouteNameType.ME })
  }
}

export const queryGuard = <T extends keyof State>(query: T, defaultQuery?: State[T]): NavigationGuard => (to, from, next) => {
  const store = storeToRefs(useQueryStore())
  // 自訂 query
  if (Object.values(to.query).length > 0) {
    next()
    return
  }
  // 紀錄 query
  if (Object.values(store[query].value).length > 0) {
    next({ ...to, query: { ...store[query].value } })
    return
  }
  // 預設 query
  if (defaultQuery) {
    next({ ...to, query: { ...defaultQuery as LocationQueryRaw } })
    return
  }
  next()
}

export const { scrollBehavior, saveScrollPositionGuard } = (() => {
  const scrollPositions: Record<RouteRecordName, Array<{ el: Element | null, top: number }>> = {}
  const saveScrollPositionGuard = (elements: string[] = []): NavigationGuard => (to, from, next) => {
    if (!from.name) return

    scrollPositions[from.name] = elements.map((item) => {
      const element = document.querySelector(item)
      return { el: element, top: element?.scrollTop ?? 0 }
    })
    next()
  }
  const scrollBehavior: RouterScrollBehavior = (to) => {
    if (to.name && to.name in scrollPositions) {
      scrollPositions[to.name].forEach((item) => {
        if (item.el) item.el.scrollTop = item.top
      })

      scrollPositions[to.name] = []
    }
    document.querySelector('#layout-content')?.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  }

  return {
    scrollBehavior,
    saveScrollPositionGuard
  }
})()

export const childrenRedirect = (children: RouteNameType[]) => {
  const { me } = storeToRefs(useAgentStore())
  const index = children.findIndex(item => routeNameMapPermissions[item].every((el) => me.value?.permissions.includes(el)))
  if (index !== -1) {
    return { name: children[index] }
  }

  return { name: RouteNameType.HOME }
}
