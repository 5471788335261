<template>
  <prime-card
    class="w-full"
    :class="{'p-4': space }"
  >
    <template
      v-if="$slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <template
      v-if="$slots.title"
      #title
    >
      <slot name="title" />
    </template>
    <template #content>
      <slot />
    </template>
    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </prime-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseCard',
  props: {
    space: {
      type: Boolean,
      default: false
    }
  }
})

</script>

<style lang="scss" scoped>
.p-card {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
}
:deep(.p-card-body) {
  @apply h-full important-p-0;
}
:deep(.p-card-content) {
  @apply h-full important-p-0;
}
</style>
