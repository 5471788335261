<template>
  <prime-toolbar
    id="desktop-toolbar"
  >
    <template #start>
      <prime-breadcrumb
        :home="home"
        :model="model"
        class="bg-transparent border-0"
      >
        <template #item="{item}">
          <router-link
            v-if="item.to"
            :to="item.to"
            class="decoration-none"
          >
            <span class="item active">{{ item.label }}</span>
          </router-link>
          <template v-else>
            <span class="item">{{ item.label }}</span>
          </template>
        </template>
      </prime-breadcrumb>
    </template>
    <template #end>
      <div class="relative">
        <prime-button
          v-if="announcementButton.display.value"
          class="announcement p-button-text p-2"
          @click="announcementButton.command"
        >
          <i
            :class="announcementButton.icon"
            class="p-button-icon p-button-icon-left"
          />
          <i
            :class="announcementButton.activeIcon"
            class="p-button-icon p-button-icon-left hidden"
          />
          <span class="p-button-label">{{ announcementButton.label }}</span>
        </prime-button>
        <prime-badge
          v-if="!!announcementButton.unread.value"
          :value="announcementButton.unread.value"
          severity="danger"
          class="absolute z-2 right-2 top--1 translate-x-100% border-circle min-w-1rem h-1rem lh-1 px-1 flex-row-center inline-flex font-normal"
        />
      </div>
      <prime-button
        v-if="!me?.is_assistant"
        icon="i-ri-wallet-line"
        :label="walletCash"
        class="p-button-text p-2 ml-3 cash"
        @mouseenter="showWalletPanel"
      />
      <prime-overlay-panel
        id="wallet_panel"
        ref="walletPanel"
        append-to="body"
        @mouseleave="hideWalletPanel"
      >
        <prime-menu
          :model="walletMenu"
          class="border-none w-5rem"
        />
      </prime-overlay-panel>
      <div
        aria-controls="member_panel"
        class="cursor-pointer ml-3 flex-row-center"
        @mouseenter="showMemberPanel"
      >
        <me-avatar
          class="mr-2 important-w-45px important-h-45px"
          image
          :avatar="me?.avatar ?? '1'"
        />
        <div>
          <div class="fw-700 text-18px lh-normal">
            {{ me?.account ?? '' }}
          </div>
          <div class="color-[var(--text-color-secondary)] text-3 lh-normal">
            {{ `${ me?.nickname } | ${ layerName} ` }}
          </div>
        </div>
      </div>
      <prime-overlay-panel
        id="member_panel"
        ref="memberPanel"
        append-to="body"
        class="member-panel"
        @mouseleave="hideMemberPanel"
      >
        <prime-menu
          :model="memberMenu"
          class="border-none w-8rem"
        />
      </prime-overlay-panel>
    </template>
  </prime-toolbar>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import type OverlayPanel from 'primevue/overlaypanel'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useAgentStore } from '@/stores/agent'
import { useLayoutStore } from '@/stores/layout'
import { useLogout } from '@/functions/useLogout'
import { AgentType, Branch, Permissions } from '@golden/gdk-agent-next'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { useNav } from '@/functions/useNav'
import { formatCurrency } from '@/utils/format'
import gdk from '@/gdk'
import { useRouter } from 'vue-router'
import { useCheckPermission } from '@/functions/useCheckPermission'
import { LayerName } from '@/constants/StatusMapping'

export default defineComponent({
  name: 'DesktopToolbar',
  setup () {
    const { t } = useI18n()
    const { me, info } = storeToRefs(useAgentStore())
    const { breadCrumb } = storeToRefs(useLayoutStore())
    const { logout } = useLogout()
    const router = useRouter()

    const announcementButton = (() => {
      const display = useCheckPermission([Permissions.ANNOUNCEMENT], 'every')
      const unread = computed(() => info.value.unread_count > 99 ? '99+' : info.value.unread_count)
      return {
        display,
        label: t('page.announcement'),
        icon: 'i-ri-notification-2-line',
        activeIcon: 'i-ri-notification-2-fill',
        command: () => { void router.push({ name: RouteNameType.ANNOUNCEMENT }) },
        unread
      }
    })()

    const account = computed(() => {
      if (me.value?.is_assistant) return me.value.parent_account.slice(0, 2)
      if (me.value?.type === AgentType.EMPEROR) return me.value.account.slice(0, 1)
      return me.value?.account.slice(0, 2)
    })

    const model = computed(() => {
      return breadCrumb.value.slice(1).map(item => ({ label: item.label }))
    })

    const home = computed(() => {
      return { label: breadCrumb.value[0]?.label ?? '', to: breadCrumb.value.length > 1 ? breadCrumb.value[0].to : '' }
    })

    const memberPanel = ref<InstanceType<typeof OverlayPanel>>()
    const walletPanel = ref<InstanceType<typeof OverlayPanel>>()
    const showMemberPanel = (event: Event) => {
      hideWalletPanel()
      memberPanel.value?.show(event)
    }
    const showWalletPanel = (event: Event) => {
      hideMemberPanel()
      walletPanel.value?.show(event)
    }
    const hideMemberPanel = () => {
      memberPanel.value?.hide()
    }
    const hideWalletPanel = () => {
      walletPanel.value?.hide()
    }

    const { nav: walletMenu } = useNav(computed(() => [
      {
        label: t('page.deposit'),
        routeName: RouteNameType.DEPOSIT
      },
      {
        label: t('page.transfer'),
        routeName: RouteNameType.TRANSFER
      },
      {
        label: t('page.record'),
        routeName: RouteNameType.RECORD
      }
    ]))
    const { nav: memberMenu } = useNav(computed(() => [
      {
        label: t('page.setting'),
        routeName: RouteNameType.SETTING
      },
      {
        label: t('page.agent-announcement'),
        routeName: RouteNameType.AGENT_ANNOUNCEMENT
      },
      {
        label: t('page.agent-deposit'),
        routeName: RouteNameType.AGENT_DEPOSIT
      },
      {
        label: t('common.logout'),
        routeName: null,
        command: () => logout()
      }
    ]))

    const walletCash = computed(() => formatCurrency(info.value.agent_cash ?? 0, { style: 'decimal' }))
    const layerName = computed(() => {
      if (!me.value) return ''
      if (me.value.is_assistant) return t(LayerName[me.value.parent_type])
      return t(LayerName[me.value.type])
    })

    onMounted(() => {
      gdk.trunk.next([Branch.AGENT_INFO])
    })

    return {
      t,
      me,
      home,
      model,
      memberPanel,
      memberMenu,
      walletPanel,
      walletMenu,
      showMemberPanel,
      showWalletPanel,
      hideMemberPanel,
      hideWalletPanel,
      account,
      logout,
      walletCash,
      layerName,
      announcementButton
    }
  }
})
</script>

<style lang="scss" scoped>
#desktop-toolbar {
  @apply h-[var(--mobile-toolbar-height)] py-0 px-4 important-hidden important-md:flex rounded-0 border-0 bg-transparent border-[var(--gray-300)] border-b-solid border-b;
}

.cash {
  font-family: DIN Alternate, Roboto, -apple-system, BlinkMacSystemFont, Arial, Noto Sans, "Microsoft YaHei", sans-serif;
}

.item {
  @apply text-18px fw-700 color-[var(--text-color)] decoration-none;
  &.active {
    @apply color-[var(--primary-color)];
  }
}

.announcement {
  &:hover .p-button-icon, &:active .p-button-icon {
    @apply first:hidden inline-block;
  }
}

.p-breadcrumb {
  @apply p-0;
  :deep(.pi.pi-chevron-right) {
    @apply i-ri-arrow-right-s-line color-[var(--primary-color)];
  }
}
</style>
