import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/i18n'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import DialogService from 'primevue/dialogservice'
import DynamicDialog from 'primevue/dynamicdialog'
import { underMaintenanceGuard, useUpdateVersion } from '@golden/shared-vue'
import { installClarity, setIdentify } from '@golden/clarity'
import { take, filter } from 'rxjs'
import gdk from './gdk'
import { checkMock } from './utils/checkMock'
import { registerSentry } from '@golden/sentry-vue'
import { SENTRY_IGNORE_ERRORS } from '@/constants/sentryIgnoreErrors'
import { SENTRY_IGNORE_CODES } from '@/constants/sentryIgnoreCodes'
import { registerGA } from '@golden/ga-vue'

import 'normalize.css'
import '@/assets/styles/global.scss'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'uno.css'

document.title = '管理后台系统'

void (async function () {
  const app = createApp(App)
  const pinia = createPinia()

  pinia.use(piniaPersist)

  app
    .use(PrimeVue, {
      zIndex: {
        modal: 1100, // dialog, sidebar
        overlay: 1000, // dropdown, overlaypanel
        menu: 1000, // overlay menus
        tooltip: 1100 // tooltip
      }
    })
    .use(router)
    .use(i18n)
    .use(pinia)
    .use(DialogService)
    .use(ToastService)
    .component('PrimeDynamicDialog', DynamicDialog)
    .component('PrimeToast', Toast)
    .directive('tooltip', Tooltip)

  if (import.meta.env.VITE_APP_RELEASE_STAGE !== 'local') {
    if (!import.meta.env.VITE_APP_SENTRY_DSN) throw new Error('Please set VITE_APP_SENTRY_DSN env for sentry.')
    registerSentry({
      app,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      stage: import.meta.env.VITE_APP_RELEASE_STAGE,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      ignoreErrors: SENTRY_IGNORE_ERRORS,
      ignoreCodes: SENTRY_IGNORE_CODES
    })
  }

  if (!['local', 'alpha', 'beta'].includes(import.meta.env.VITE_APP_RELEASE_STAGE)) {
    if (!import.meta.env.VITE_APP_GA_ID) throw new Error('Please set VITE_APP_GA_ID env for google analytics.')
    registerGA({ app, router, id: import.meta.env.VITE_APP_GA_ID })
  }

  if (import.meta.env.VITE_APP_CLARITY_ID) {
    installClarity(import.meta.env.VITE_APP_CLARITY_ID)
    gdk.agent.me.pipe(
      filter((data) => !!data?.account),
      take(1)
    ).subscribe((data) => { setIdentify(data?.account as string) })
  }

  await checkMock()

  await router.isReady()
  app.mount('#app')

  underMaintenanceGuard(gdk, import.meta.env.BASE_URL)
  const { triggerUpdate } = useUpdateVersion(gdk, import.meta.env.BASE_URL)
  router.beforeEach((to, _, resolve) => {
    resolve()
    triggerUpdate(() => { window.location.href = to.fullPath })
  })
})()
