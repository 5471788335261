import { defineStore } from 'pinia'
import { Branch } from '@golden/gdk-agent-next'
import gdk from '../gdk'
import { createGDKStore } from '@golden/shared-vue'

const gdkObj = {
  me: {
    subject: gdk.agent.me,
    branch: Branch.ME
  },
  info: {
    subject: gdk.agent.info,
    branch: Branch.AGENT_INFO
  }
}

export const useAgentStore = defineStore('agent', () => createGDKStore(gdk, gdkObj))
