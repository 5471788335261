<template>
  <base-card class="p-5">
    <div class="mb-4 flex-row-between">
      <h4 class="m-0 lh-normal">
        {{ title }}
      </h4>
      <div :id="id" />
    </div>
    <div
      class="relative"
      :class="{ 'pt-50%': loading }"
    >
      <div :class="{ 'absolute-full': loading, 'overflow-x-auto': !isDesktop, 'overflow-x-overlay': isDesktop }">
        <base-skeleton
          :loading="loading"
          :options="{ size: '100%' }"
        >
          <prime-chart
            v-bind="$attrs"
            @loaded="handleLoaded"
          />
        </base-skeleton>
      </div>
    </div>
    <template
      v-if="chart"
      #footer
    >
      <prime-scroll-panel class="h-9 w-full">
        <div class="flex w-full">
          <div
            v-for="(button, index) in labels"
            :key="index"
            class="whitespace-nowrap mr-[calc(0.5rem-1px)] last:mr-0"
          >
            <prime-button
              class="p-button-sm p-button-rounded important-py-2 important-px-4 mr-1px"
              :class="{ 'p-button-outlined': button.value !== modelValue }"
              @click="() => handleClick(button.value)"
            >
              {{ button.label }}
            </prime-button>
          </div>
        </div>
      </prime-scroll-panel>
    </template>
  </base-card>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import type { Chart } from 'chart.js'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'HomeChartCard',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    labels: {
      type: Object as PropType<Array<{ label: string, value: string }>>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:hidden'],
  setup (_, context) {
    const chart = ref<Chart>()
    const handleLoaded = (c: Chart) => { chart.value = c }
    const handleClick = (value: string) => {
      if (!chart.value) return
      context.emit('update:hidden', [!chart.value.isDatasetVisible(0), !chart.value.isDatasetVisible(1)])
      context.emit('update:modelValue', value)
    }
    const { isDesktop } = storeToRefs(useMediaQueryStore())
    return {
      handleLoaded,
      handleClick,
      chart,
      isDesktop
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.p-scrollpanel-bar) {
  @apply bg-truegray op-70 focus:shadow-none h-1;
}
:deep(.p-scrollpanel-content) {
  @apply w-full pr-0 pb-0;
}
</style>
