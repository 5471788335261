<template>
  <div class="-m-2">
    <div class="flex flex-wrap">
      <div
        v-for="item in options"
        :key="item.value"
        :class="{ 'p-button-outlined': !modelValue.includes(item.value) }"
        class="w-33.33% px-2 pt-2"
      >
        <prime-button
          class="p-button-rounded p-2 md:px-3 w-full"
          :class="{ 'p-button-outlined': !modelValue.includes(item.value) }"
          @click="() => onClick(item.value)"
        >
          <span class="text-xs md:text-sm mx-auto">
            {{ item.name }}
          </span>
        </prime-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseStatusSelect',
  props: {
    modelValue: {
      type: Array as PropType<Array<number | string>>,
      default: () => ([])
    },
    options: {
      type: Array as PropType<Array<{ name: string, value: number | string }>>,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const onClick = (value: number | string) => {
      const index = props.modelValue.findIndex(item => item === value)
      const newValue = [...props.modelValue]
      if (index === -1) {
        newValue.push(value)
      } else {
        newValue.splice(index, 1)
      }
      context.emit('update:modelValue', newValue)
    }

    return {
      onClick
    }
  }
})
</script>
