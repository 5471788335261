import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useDialog } from './useDialog'

export const useLogout = () => {
  const { t } = useI18n()
  const router = useRouter()
  const { confirm } = useDialog()
  const logout = () => {
    void confirm(t('message.logout.confirm'))
      .then(() => { void router.push({ name: 'logout' }) })
      .catch(() => 1)
  }
  return {
    logout,
    logoutText: t('common.logout')
  }
}
