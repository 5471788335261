<template>
  <base-account-search-button
    v-model:visible="visible"
    :is-first-load="isFirstLoad && account.length > 1"
    :loading="loading && account.length > 1"
    :empty="list.length === 0"
    :finished="finished"
    @input="handleInput"
    @scroll="scroll"
    @after-hide="() => type = 'account'"
  >
    <template #toolbar>
      <div class="flex overflow-x-auto">
        <prime-button
          v-for="option in options"
          :key="option.type"
          :label="option.label"
          class="p-button-sm p-button-rounded mr-2 last:mr-0 flex-shrink-0"
          :class="{ 'p-button-outlined': type !== option.type }"
          @click="() => type = option.type"
        />
      </div>
    </template>
    <div v-if="!isFirstLoad">
      <router-link
        v-for="item in list"
        v-slot="{ navigate }"
        :key="item.id"
        :to="`/users/players/${item.id}`"
        custom
      >
        <div
          v-if="item.type === 'agent'"
          class="flex items-center cursor-pointer bg-white py-10px px-4 mb-4px last:mb-0 decoration-none"
          @click="() => click(item)"
        >
          <prime-avatar
            shape="circle"
            class="mr-2 important-w-35px important-h-35px"
            :image="getAvatars(Number(item.avatar ?? 0))"
          />
          <div>
            <div class="text-4 color-[var(--text-color)]">
              {{ item.account }}
            </div>
            <div class="mt-1 text-3 color-[var(--text-color-secondary)]">
              {{ getInfo(item) }}
            </div>
          </div>
        </div>
        <a
          v-else
          class="flex items-center cursor-pointer bg-white py-10px px-4 mb-4px last:mb-0 decoration-none"
          @click="goToDetail(navigate)"
        >
          <prime-avatar
            shape="circle"
            icon="i-ri-user-fill"
            class="mr-2 important-w-35px important-h-35px"
          />
          <div>
            <div class="text-4 color-[var(--text-color)]">{{ item.account }}</div>
            <div class="mt-1 text-3 color-[var(--text-color-secondary)]">{{ getInfo(item) }}
            </div>
          </div>
        </a>
      </router-link>
    </div>
  </base-account-search-button>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHistoryStore } from '@/stores/history'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { PlayersSearchReq, PlayersSearchRes, PaginationReq, AgentType } from '@golden/gdk-agent-next'
import { usePagination, defaultPaginationData } from '@golden/shared-vue'
import { of } from 'rxjs'
import gdk from '@/gdk'
import { getAvatars } from '@/utils/getAvatar'

export default defineComponent({
  name: 'PlayerSearch',
  emits: ['click'],
  setup (_, context) {
    const { t } = useI18n()
    const account = ref('')
    const type = ref('account')
    const visible = ref(false)
    const store = useHistoryStore()
    const { playersHistory, needSetFlag } = storeToRefs(store)
    const { me } = storeToRefs(useAgentStore())

    const options = computed(() => [
      {
        label: t('common.account'),
        type: 'account'
      },
      {
        label: t('common.nickname'),
        type: 'nickname'
      },
      {
        label: t('common.name'),
        type: 'real_name'
      },
      {
        label: t('common.siguaId'),
        type: 'bubble_id'
      },
      ...me.value?.type === AgentType.STAFF || (me.value?.is_assistant && me.value.parent_type === AgentType.STAFF)
        ? []
        : [
            {
              label: t('common.agentLayer'),
              type: 'agent'
            }
          ]
    ])
    const list = computed(() => account.value.length > 1 ? players.value : playersHistory.value[me.value?.id as number] ?? [])

    const { players, loading, finished, isFirstLoad, scroll } = usePagination(
      'players',
      computed(() => ({ query_string: account.value, type: type.value })),
      (params) => params.query_string.length > 1
        ? gdk.user.getPlayersSearch(params as PlayersSearchReq & PaginationReq)
        : of(defaultPaginationData([] as PlayersSearchRes[]))
    )

    const goToDetail = (navigate: () => void) => {
      needSetFlag.value = true
      navigate()
      visible.value = false
    }

    const click = (payload: PlayersSearchRes) => {
      store.addHistory('playersHistory', payload)
      visible.value = false
      context.emit('click', { ...payload })
    }

    const handleInput = (value: string) => { account.value = value }
    const getInfo = (item: PlayersSearchRes) => {
      const arr = []
      if (item.nickname) arr.push(`${t('common.nickname')} ${item.nickname}`)
      if (item.real_name) arr.push(`${t('common.name')} ${item.real_name}`)
      if (item.bubble_id) arr.push(`${t('common.siguaId')} ${item.bubble_id}`)
      return arr.join(' / ')
    }

    return {
      t,
      account,
      options,
      list,
      loading,
      isFirstLoad,
      finished,
      type,
      scroll,
      visible,
      handleInput,
      getInfo,
      goToDetail,
      click,
      getAvatars
    }
  }
})
</script>
