<template>
  <base-input
    v-model="inputValue"
    v-bind="$attrs"
    class="password"
    :type="show ? 'text' : 'password'"
  >
    <template #rightIcon="{ clearable }">
      <i
        v-show="clearable"
        class="i-ri-close-circle-fill ml-2 bg-truegray-500 cursor-pointer p-inputgroup-addon right-10 color-[#6c757d]"
        @click="clear"
      />
      <i
        class="cursor-pointer p-inputgroup-addonbg-truegray-500 mx-2 text-5"
        :class="{ 'i-ri-eye-line': show, 'i-ri-eye-off-line': !show }"
        @click="toggle"
      />
    </template>
  </base-input>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'BasePassword',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const inputValue = computed<string>({
      get () {
        return props.modelValue
      },
      set (value: string) {
        context.emit('update:modelValue', value)
      }
    })
    const clear = () => {
      inputValue.value = ''
    }

    const show = ref(false)
    const toggle = () => {
      show.value = !show.value
    }
    return {
      inputValue,
      clear,
      show,
      toggle
    }
  }
})
</script>
