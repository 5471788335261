<template>
  <base-card
    v-if="isMobile"
    class="p-16px"
  >
    <div class="flex-row-between">
      <div class="w-50% flex-row-center">
        <div class="relative w-150px pt-150px">
          <div class="absolute-full">
            <base-skeleton
              :loading="loading"
              :options="{ size: '100%', shape: 'circle' }"
            >
              <prime-chart
                ref="chart"
                :data="data"
                :options="options"
                :type="type"
              />
              <div
                class="value"
              >
                {{ formatPercent(percent) }}
              </div>
            </base-skeleton>
          </div>
        </div>
      </div>
      <div class="w-50%">
        <div
          v-for="(label, i) in labels"
          :key="i"
          class="flex-row-start p-2"
        >
          <div
            class="w-10px h-5px mr-4px"
            :style="{ background: label.backgroundColor }"
          />
          <base-skeleton
            :loading="loading"
            :options="{ width: '102px' }"
          >
            <span class="lh-normal">
              {{ label.name }} {{ label.value }}
            </span>
          </base-skeleton>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDoughnutChart } from '@/functions/useCommonChart'
import { PlayersResSummary } from '@golden/gdk-agent-next'
import { DoughnutColor } from '@/constants/ChartColor'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'
import { formatPercent } from '@/utils/format'

export default defineComponent({
  name: 'PlayersSummaryCard',
  props: {
    rawData: {
      type: Object as PropType<PlayersResSummary>,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()

    const { data, options, type, chart } = useDoughnutChart(computed(() => ({
      datasets: [
        {
          data: [props.rawData?.deposit_count ?? 0, (props.rawData?.register_count ?? 0) - (props.rawData?.deposit_count ?? 0)],
          backgroundColor: DoughnutColor
        }
      ]
    })))

    const percent = computed(() => {
      if (!props.rawData || props.rawData.register_count === 0) return 0
      return (props.rawData.deposit_count / props.rawData.register_count) * 100
    })

    const labels = computed(() => [
      {
        name: t('common.playerDepositCount'),
        value: props.rawData?.deposit_count ?? 0
      },
      {
        name: t('common.playerRigesterCount'),
        value: props.rawData?.register_count ?? 0
      }
    ].map((el, i) => ({ ...el, backgroundColor: DoughnutColor[i] })))

    const { isMobile } = storeToRefs(useMediaQueryStore())

    return {
      t,
      data,
      options,
      type,
      percent,
      labels,
      chart,
      isMobile,
      formatPercent
    }
  }
})
</script>

<style lang="scss" scoped>
.value {
  @apply absolute left-50% top-50% translate--50% text-16px color-[var(--text-color)];
  font-family: DIN Alternate, Roboto, -apple-system, BlinkMacSystemFont, Arial, Noto Sans, "Microsoft YaHei", sans-serif;
}
</style>
