<template>
  <home-chart-card
    :id="id"
    v-model="current"
    :title="t('overview.platform')"
    :labels="labels"
    :data="data"
    :options="options"
    :plugins="plugins"
    :type="type"
    :style="{ 'min-width': `${minWidth}px` }"
    @update:hidden="handleUpdateHidden"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { HomeRes } from '@golden/gdk-agent-next'
import { useBarChart } from '@/functions/useCommonChart'
import { mergeArray, uniqueId } from '@golden/utils'
import { BarColor } from '@/constants/ChartColor'
import { formatHomeChartValue } from '@/utils/format'
import { storeToRefs } from 'pinia'
import { useMediaQueryStore } from '@/stores/screen'
import { BreakPoints } from '@/constants/BreakPoints'
import { useGames } from '@/functions/useGames'
type OptionType = keyof HomeRes['this_month']['platform_summary'][number]

export default defineComponent({
  name: 'PlatformCard',
  props: {
    rawData: {
      type: Object as PropType<HomeRes>,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const { games: storeGames } = useGames()
    const { isDesktop } = storeToRefs(useMediaQueryStore())
    const id = uniqueId('home-platform-')
    const current = ref<OptionType>('effective_bet')
    const { data, options, plugins, type } = useBarChart(id, computed(() => {
      const games = mergeArray(storeGames.value, props.rawData.this_month.platform_summary, (game, item) => game.id === item.game_id).map(([game, el]) => ({
        title: game.instance.name,
        thisMonth: formatHomeChartValue(el[current.value]),
        lastMonth: formatHomeChartValue(props.rawData.last_month.platform_summary.find((item) => item.game_id === el.game_id)?.[current.value] ?? 0)
      })).filter((el) => el.thisMonth !== 0 || el.lastMonth !== 0)

      return {
        labels: games.map((el) => el.title),
        datasets: [
          {
            data: games.map((el) => el.thisMonth),
            label: t('common.thisMonth')
          },
          {
            data: games.map((el) => el.lastMonth),
            label: t('common.lastMonth')
          }
        ].map((el, i) => ({ ...el, backgroundColor: BarColor[i], hidden: hidden.value[i] }))
      }
    }), computed(() => ({
      aspectRatio: isDesktop.value || minWidth.value > BreakPoints.md ? 4 : 2
    })))
    const hidden = ref([false, false])
    const handleUpdateHidden = (value: boolean[]) => { hidden.value = value }

    const labels = computed(() => [
      {
        label: t('common.effectiveBet'),
        value: 'effective_bet'
      },
      {
        label: t('common.total', { item: t('common.revenue') }),
        value: 'revenue'
      }
    ] as Array<{ label: string, value: OptionType }>)

    const minWidth = computed(() => data.value.labels.length * 70)
    return {
      id,
      t,
      labels,
      data,
      options,
      plugins,
      type,
      current,
      hidden,
      handleUpdateHidden,
      minWidth
    }
  }
})
</script>
