<template>
  <prime-skeleton
    v-if="loading"
    v-bind="{ ...options, ...$attrs}"
  />
  <slot v-else />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type { SkeletonProps } from 'primevue/skeleton'

export default defineComponent({
  name: 'BaseSkeleton',
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object as PropType<SkeletonProps>,
      default: () => ({})
    }
  },
  setup () {
  }
})
</script>
