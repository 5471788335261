<template>
  <prime-avatar
    v-bind="$attrs"
    :label="image ? '': label"
    :image="image ? getAvatars(Number(me?.avatar ?? 0)) : undefined"
    shape="circle"
    @click="openDialog"
  />
  <base-media-query-dialog
    v-model:visible="visible"
    class="avatar-dialog"
    width="375px"
  >
    <template #header>
      <h3 class="text-center w-full m-0 fw-600 text-6">
        {{ t('common.changeAvatar') }}
      </h3>
    </template>
    <div class="bg-white">
      <div class="flex-row-between flex-wrap w-320px mx-auto pb-28px">
        <div
          v-for="index in 30"
          :key="index"
          class="py-5px cursor-pointer"
        >
          <div
            class="w-56px h-56px relative"
            :class="{ active: index.toString() === avatar }"
            @click="() => update(index.toString())"
          >
            <img :src="getAvatars(index)">
            <div
              v-if="index.toString() === avatar"
              class="absolute bottom--8px left-50% translate-x--50% z-2 color-white scale-57.1428571%  bg-[var(--blue-500)] rd-12px break-keep"
            >
              <div class="flex-row-center py-5px px-10px">
                <i class="i-ri-check-line mr-3px" />
                <span>{{ t('common.currentUse') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-media-query-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useAgentStore } from '@/stores/agent'
import { AgentType } from '@golden/gdk-agent-next'
import gdk from '@/gdk'
import { lastValueFrom } from 'rxjs'
import { getAvatars } from '@/utils/getAvatar'

export default defineComponent({
  name: 'MeAvatar',
  inheritAttrs: true,
  props: {
    image: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: String,
      required: true
    }
  },
  setup () {
    const { t } = useI18n()
    const { me } = storeToRefs(useAgentStore())
    const visible = ref(false)
    const label = computed(() => {
      if (me.value?.is_assistant) return me.value.parent_account.slice(0, 2)
      if (me.value?.type === AgentType.EMPEROR) return me.value.account.slice(0, 1)
      return me.value?.account.slice(0, 2)
    })

    const update = async (id: string) => {
      await lastValueFrom(gdk.agent.updateMe({
        avatar: id
      }))

      visible.value = false
    }

    const openDialog = () => {
      if (me.value?.is_assistant) return
      visible.value = true
    }

    return {
      t,
      me,
      visible,
      label,
      getAvatars,
      update,
      openDialog
    }
  }
})
</script>

<style lang="scss" scoped>

.active {
  &::after {
   content: '';
   @apply absolute inset-0 m-auto w-52px h-52px b-2px b-solid b-[var(--blue-500)] rd-50%;
  }
}
</style>
