<template>
  <span class="p-buttonset base-group-date-button">
    <prime-button
      :icon="'i-ri-calendar-line'"
      :label="buttonLabel"
      class="p-button-sm"
      :class="{ 'p-button-outlined': !date }"
      @click="click"
    />
    <base-date-button
      v-bind="$attrs"
      v-model="date"
      :show-icon="false"
      :show-shortcuts="showShortcuts"
      :clearable="clearable"
      :min-date="minDate"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { DateShortcutType, getTaipeiNowTime, startOfMonthTaipei } from '@/utils/TimeHelper'
import { subMonths } from 'date-fns'

export default defineComponent({
  name: 'BaseGroupDateButton',
  inheritAttrs: false,
  props: {
    buttonLabel: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [Array, Date, undefined] as PropType<Date[] | Date | undefined>,
      default: undefined
    },
    showShortcuts: {
      type: Array as PropType<DateShortcutType[]>,
      default: () => (['today', 'yesterday', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth'])
    },
    minDate: {
      type: Date,
      default: startOfMonthTaipei(subMonths(getTaipeiNowTime(), 1))
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'click'],
  setup (props, context) {
    const click = () => {
      context.emit('click')
    }

    const date = computed<Date[] | Date | undefined>({
      get () {
        return props.modelValue
      },
      set (value) {
        context.emit('update:modelValue', value)
      }
    })

    return {
      click,
      date
    }
  }
})
</script>

<style lang="scss" scoped>
.base-group-date-button {
  :deep(.p-button) {
    @apply focus:shadow-none;
  }
}
</style>
