import { ref } from 'vue'
import { useObservable } from '@golden/shared-vue'
import { Subject, finalize, map, switchMap, tap } from 'rxjs'
import { GDKError } from '@golden/gdk-agent-next'
import gdk from '@/gdk'
import { useToast } from 'primevue/usetoast'
import { urlHelper } from '@/utils/urlHelper'
import { openWindow } from '@golden/shared'
import { useDialog } from './useDialog'
import { useI18n } from 'vue-i18n'

export function useBannerNotification () {
  const loading = ref(true)
  const toast = useToast()
  const { confirm } = useDialog()
  const { t } = useI18n()

  const flow$ = new Subject<void>()
  const bannerNotifications = useObservable(flow$.pipe(
    tap(() => { loading.value = true }),
    switchMap(() => gdk.notification.getBannerNotification()),
    map((res) => res.data),
    finalize(() => { loading.value = false })
  ), null)

  const gotoBannerLink = (id: number) => {
    gdk.notification.getBannerNotificationLink(id).subscribe({
      next: (res) => {
        let win: Window
        const href = res.url.startsWith('https://') ? res.url : `${urlHelper.get('main')}${res.url}`
        try {
          win = openWindow(`_golden_banner_link_${id}`, href, undefined, undefined, '')
        } catch (e) {
          void confirm(
            t('tip.bannerNotification.newWindowTip')
          )
            .then(() => {
              win = openWindow(`_golden_banner_link_${id}`, href, undefined, undefined, '')
            })
            .catch(() => 1)
        }
      },
      error: (err: GDKError) => {
        void flow$.next()
        void toast.add({ severity: 'error', detail: (err as Error).message, life: 1000 })
      }
    })
  }

  const reload = () => {
    flow$.next()
  }

  reload()

  return {
    loading,
    bannerNotifications,
    gotoBannerLink,
    reload
  }
}
