<template>
  <home-chart-card
    :id="id"
    v-model="current"
    :title="t('overview.bet')"
    :labels="labels"
    :data="data"
    :options="options"
    :plugins="plugins"
    :type="type"
    @update:hidden="handleUpdateHidden"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { HomeRes } from '@golden/gdk-agent-next'
import { useLineChart } from '@/functions/useCommonChart'
import { uniqueId } from '@golden/utils'
import { LineColor } from '@/constants/ChartColor'
import { formatHomeChartValue } from '@/utils/format'

type OptionType = keyof HomeRes['this_month']['bet_summary']

export default defineComponent({
  name: 'BetCard',
  props: {
    rawData: {
      type: Object as PropType<HomeRes>,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()

    const id = uniqueId('home-bet-')
    const current = ref<OptionType>('user_count')
    const hidden = ref([false, false])
    const { data, options, type, plugins } = useLineChart(id, computed(() => ({
      datasets: [
        {
          data: props.rawData.this_month.bet_summary[current.value].map((el) => formatHomeChartValue(el)),
          label: t('common.thisMonth')
        },
        {
          data: props.rawData.last_month.bet_summary[current.value].map((el) => formatHomeChartValue(el)),
          label: t('common.lastMonth')
        }
      ].map((el, i) => ({ ...el, borderColor: LineColor[i], backgroundColor: LineColor[i], hidden: hidden.value[i] }))
    })))
    const handleUpdateHidden = (value: boolean[]) => { hidden.value = value }

    const labels = computed(() => [
      {
        label: t('common.numberOfPeople', { prefix: t('common.bet') }),
        value: 'user_count'
      },
      {
        label: t('common.numberOfCash', { prefix: t('common.bet') }),
        value: 'cash'
      },
      {
        label: t('common.effectiveBet'),
        value: 'effective_bet'
      },
      {
        label: t('common.total', { item: t('common.revenue') }),
        value: 'revenue'
      }
    ] as Array<{ label: string, value: OptionType }>)

    return {
      id,
      t,
      labels,
      data,
      options,
      type,
      plugins,
      current,
      hidden,
      handleUpdateHidden
    }
  }
})
</script>
