<template>
  <base-table
    v-bind="$attrs"
    :cols="cols"
    :rows="rows"
    @row-click="rowClick"
  >
    <template #account="{ data }">
      <div>
        <div>
          {{ data.account }}
        </div>
        <div
          v-if="data.nickname"
          v-tooltip.top="data.nickname.length > 5 ? data.nickname : ''"
          class="text-sm color-zinc-400"
        >
          {{ `${data.nickname.slice(0, 5)}${data.nickname.length > 5 ? '...' : ''}` }}
        </div>
      </div>
    </template>
    <template #game="{ data }">
      {{ data.gameName }}
    </template>
    <template #bet_cash="{ data }">
      <base-cash
        :cash="data.bet_cash"
        no-color
      />
    </template>
    <template #effective_bet="{ data }">
      <base-cash
        :cash="data.effective_bet"
        no-color
      />
    </template>
    <template #revenue="{ data }">
      <div class="flex-row-center">
        <base-cash :cash="data.revenue" />
        <base-link-icon @click="() => click(data)" />
      </div>
    </template>
  </base-table>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { formatDate } from '@/utils/format'
import { LayerName, NextLayer } from '@/constants/StatusMapping'
import { AccountSearchType, BetPlayerRes, AgentType, BetAgentRes } from '@golden/gdk-agent-next'
import { DataTableRowClickEvent } from 'primevue/datatable'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'
import { TableColType } from '@/utils/TableHelper'

export default defineComponent({
  name: 'BetTable',
  props: {
    rows: {
      type: Array as PropType<Array<BetAgentRes | BetPlayerRes>>,
      default: () => ([])
    },
    layer: {
      type: Number as PropType<AgentType | -1>,
      required: true
    }
  },
  emits: ['click'],
  setup (props, context) {
    const { t } = useI18n()
    const cols = computed<TableColType[]>(() =>
      props.layer !== -1
        ? [
            {
              field: 'account',
              header: t(LayerName[NextLayer[props.layer]]),
              style: { 'min-width': '80px', height: '56px' }
            },
            {
              field: 'bet_player',
              header: t('common.betPeople'),
              align: 'end'
            },
            {
              field: 'bet_cash',
              header: t('common.betCash'),
              align: 'end'
            },
            {
              field: 'effective_bet',
              header: t('common.effectiveBetCash'),
              style: { 'min-width': '160px' },
              align: 'end'
            },
            {
              field: 'revenue',
              header: t('common.revenue'),
              style: { 'min-width': '160px' },
              align: 'end'
            }
          ]
        : [
            {
              field: 'game',
              header: t('common.gamePlatform')
            },
            {
              field: 'bet_cash',
              header: t('common.betCash'),
              align: 'end'
            },
            {
              field: 'effective_bet',
              header: t('common.effectiveBetCash'),
              style: { 'min-width': '160px' },
              align: 'end'
            },
            {
              field: 'revenue',
              header: t('common.revenue'),
              style: { 'min-width': '160px' },
              align: 'end'
            }
          ]
    )
    const { isDesktop } = storeToRefs(useMediaQueryStore())
    const rowClick = (event: DataTableRowClickEvent) => {
      if (isDesktop.value) return
      click(event.data)
    }

    const click = (data: unknown) => {
      context.emit('click', data)
    }

    return {
      t,
      cols,
      formatDate,
      click,
      rowClick,
      AccountSearchType
    }
  }
})
</script>
