export async function checkMock (): Promise<void> {
  const env = import.meta.env.VITE_APP_MOCK
  if (!import.meta.env.PROD && env) {
    const { ModuleType, mockStart } = (await import('@golden/mock')).default
    mockStart({
      module: ModuleType.AGENT_NEXT,
      ignoreSkip: env === 'ignore-skip' || env === 'ignore-both',
      ignoreOnly: env === 'ignore-only' || env === 'ignore-both'
    })
  }
}
