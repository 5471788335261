import { pwaRequestInterceptor } from '@golden/app-plugins'
import { AgentMe, AssistantMe, Branch, createAgentModule, getDefaultInterceptor, Lang } from '@golden/gdk-agent-next'
import { filter, take, timeout, lastValueFrom } from 'rxjs'
import { urlHelper } from './utils/urlHelper'

export const createInterceptor: typeof getDefaultInterceptor = (auth, lang) => {
  const defaultInterceptor = getDefaultInterceptor(auth, lang)

  return {
    request: () => (source) => source.pipe(
      defaultInterceptor.request(),
      pwaRequestInterceptor()
    ),
    response: () => (source) => source.pipe(
      defaultInterceptor.response()
    ),
    error: () => (source) => source.pipe(
      defaultInterceptor.error()
    )
  }
}

const gdk = createAgentModule(
  urlHelper.get('api'),
  urlHelper.get('ws'),
  30000,
  Lang.ZH_CN,
  createInterceptor
)

export async function getAgentMe (): Promise<AgentMe | AssistantMe | null> {
  if (!gdk.agent.me.value) gdk.trunk.next([Branch.ME])
  return await lastValueFrom(gdk.agent.me.pipe(timeout(15000), filter(item => item !== null), take(1)))
}

export default gdk
