import { createI18n } from 'vue-i18n'
import zhCN from '../locales/zh-CN'

export const supportLocales = ['zh-CN', 'zh-TW', 'en-US'] as const
export const defaultLocale = 'zh-CN'

export type MessageSchema = typeof zhCN

const i18n = createI18n<[MessageSchema], 'zh-CN', false>({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    [defaultLocale]: zhCN
  },
  numberFormats: {
    [defaultLocale]: {
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      signed: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'always'
      },
      usdt: {
        style: 'decimal', minimumFractionDigits: 4, maximumFractionDigits: 4
      },
      currency: {
        style: 'currency',
        currency: 'CNY',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
      }
    }
  }
})

export default i18n
