<template>
  <div
    class="ml-2"
    :style="{ height: `${size}px`, width: `${size}px` }"
  >
    <prime-button
      v-if="!hidden"
      icon="i-ri-arrow-right-s-line color-white"
      class="p-button-rounded p-button-text text-8 important-bg-#3B82F6 important:h-full important:w-full important:p-0"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'BaseLinkIcon',
  props: {
    size: {
      type: Number,
      default: 20
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { isDesktop } = storeToRefs(useMediaQueryStore())

    return {
      isDesktop
    }
  }
})
</script>
