import { Ref, computed, ref } from 'vue'
import { useObservable } from '@golden/shared-vue'
import { GameCategoryFactory, GameBase, GameFactory, PlatformFactory } from '@golden/game-china'
import { finalize, map, tap } from 'rxjs'
import gdk from '@/gdk'
import { Game, GameType, PlatformType } from '@golden/gdk-agent-next'

export type StoreGame = Game & {
  instance: GameBase
}

export function useGames () {
  const loading = ref(true)
  const mainWalletName = PlatformFactory.create(PlatformType.GOLDEN).walletName
  const games: Ref<StoreGame[]> = useObservable(gdk.common.getGames().pipe(
    tap(() => { loading.value = true }),
    finalize(() => { loading.value = false }),
    map((games) => (games.reduce<StoreGame[]>((pre, cur) => {
      try {
        return [...pre, { ...cur, instance: GameFactory.create(cur.id, null) }]
      } catch {
        return pre
      }
    }, []).sort((a, b) => a.instance.order - b.instance.order)))
  ), [])
  const getGameName = (id: GameType) => games.value.find((el) => el.id === id)?.instance?.name ?? ''
  const categories = computed(() => GameCategoryFactory.createAll().map((instance) => ({
    instance,
    games: games.value.filter((el) => el.category === instance.id)
  })))
  return {
    loading,
    games,
    categories,
    getGameName,
    mainWalletName
  }
}
