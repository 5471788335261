<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="$style.base"
    v-html="SvgSprite"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { SvgSprite } from '@golden/game-china'

export default defineComponent({
  name: 'GameSvgSprite',
  setup () {
    return {
      SvgSprite
    }
  }
})
</script>

<style lang="scss" module>
.base {
  position: absolute;
  >svg {
    width: 0;
    height: 0;
  }
}
</style>
