<template>
  <header class="h-[var(--mobile-toolbar-height)] py-0 px-4 z-4 flex-row-between md:hidden border-b-solid border-b border-[var(--gray-300)] pt-[var(--safe-area-inset-top)] box-content">
    <div class="start">
      <slot name="start" />
    </div>
    <slot />
    <div
      class="end"
    >
      <slot name="end" />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LayoutTitle'
})
</script>
