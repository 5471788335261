<template>
  <span
    class="base-input flex"
    :class="{
      'p-inputgroup': $slots.leftAddon || $slots.rightAddon,
      'important-w-full': full
    }"
  >
    <slot name="leftAddon" />
    <span
      class="p-inputtext flex w-full middle"
      :class="{ 'p-inputgroup': $slots.leftIcon || $slots.rightIcon || clearable, 'p-invalid': error, inline }"
    >
      <slot name="leftIcon" />
      <prime-textarea
        v-if="multiline"
        v-bind="$attrs"
        v-model="inputValue"
        :disabled="disabled"
        :readonly="readonly"
        rows="1"
        auto-resize
        class="important-w-full important-p-0 placeholder:color-truegray"
        @compositionstart="() => isComposition = true"
        @compositionend="handleCompositionend"
        @input="handleInput"
      />
      <prime-input-text
        v-else
        v-bind="$attrs"
        v-model="inputValue"
        :disabled="disabled"
        :readonly="readonly"
        class="important-w-full placeholder:color-truegray"
        @compositionstart="() => isComposition = true"
        @compositionend="handleCompositionend"
        @input="handleInput"
      />
      <slot
        name="rightIcon"
        :clearable="clearable"
      >
        <i
          v-show="clearable"
          class="i-ri-close-circle-fill bg-[var(--text-color-secondary)] mx-2 cursor-pointer p-inputgroup-addon"
          @click="clear"
        />
      </slot>
    </span>
    <slot name="rightAddon" />
  </span>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, nextTick } from 'vue'

export default defineComponent({
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    full: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    normalize: {
      type: Function as unknown as PropType<(data: string) => string>,
      default: undefined
    },
    inline: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup (props, context) {
    const isComposition = ref(false)
    const inputValue = computed<string>({
      get () {
        return props.modelValue
      },
      set (value: string) {
        if (isComposition.value) return
        context.emit('update:modelValue', value)
      }
    })
    const clear = () => {
      inputValue.value = ''
    }
    const clearable = computed(() => !!inputValue.value && !props.readonly && !props.disabled)
    const isGroup = computed(() => !!context.slots.leftAddon || !!context.slots.rightAddon)
    const handleCompositionend = (e: Event) => {
      isComposition.value = false
      inputValue.value = (e.target as HTMLInputElement).value
    }
    const handleInput = (e: Event) => {
      void context.emit('change', e)
      if (!props.normalize) return

      const target = e.target as HTMLInputElement
      const result = props.normalize(target.value)
      handleCompositionend(e)

      if (result !== target.value) {
        void nextTick(() => {
          void context.emit('update:modelValue', result)
          target.value = result
        })
      }
    }

    return {
      inputValue,
      isComposition,
      clear,
      clearable,
      isGroup,
      handleInput,
      handleCompositionend
    }
  }
})
</script>

<style lang="scss" scoped>
.base-input {
  :deep(.middle) {
    @apply flex-row-center p-0 rounded-0 first:rounded-l-1.5 last:rounded-r-1.5;
    &.p-invalid {
      @apply border-[#e24c4c];
    }

    &.inline {
      @apply border-0 focus-within:shadow-none;
    }
    &:focus-within {
      @apply border-[#3B82F6];
    }
    > .p-inputtext {
      @apply border-none lh-none;
      &:enabled:focus {
        @apply shadow-none border-[#ced4da];
      }
    }
    > .p-inputgroup-addon {
      @apply border-none p-0 min-w-16px h-16px;
    }
  }
  :deep(.p-inputtext-sm + .pi) {
    @apply text-3.5;
  }
}
</style>
