import { useI18n } from 'vue-i18n'
import { computed, Ref } from 'vue'

export const useScrollTip = (options: {
  rowsLength: Ref<number>,
  perPage: Ref<number>,
  isFirstLoad: Ref<boolean>,
  loading: Ref<boolean>,
  finished: Ref<boolean>,
  emptyMessage?: string
}) => {
  const { rowsLength, isFirstLoad, loading, finished, emptyMessage = '' } = options
  const { t } = useI18n()

  const footerTip = computed(() => {
    if (isFirstLoad.value) return ''
    if (loading.value) return t('common.isLoading')
    if (rowsLength.value === 0) return emptyMessage
    if (finished.value) return t('common.scrollFinished')
    return ''
  })

  return {
    scroll,
    footerTip
  }
}
