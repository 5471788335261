<template>
  <span :class="className">
    {{ t(nameMap[status]) }}
  </span>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

type Status = string | number
type StatusMap = Record<Status, string>

export default defineComponent({
  name: 'BaseStatus',
  props: {
    colorMap: {
      type: Object as PropType<StatusMap>,
      default: null
    },
    nameMap: {
      type: Object as PropType<StatusMap>,
      required: true
    },
    status: {
      type: [String, Number] as PropType<Status>,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const className = computed(() => {
      if (props.colorMap) return props.colorMap[props.status]
      return ''
    })

    return {
      t,
      className
    }
  }
})
</script>
