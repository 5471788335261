<template>
  <span
    v-tooltip.top="tooltip ?? data"
    class="truncate"
    :class="{ link: !disabled }"
    @click.stop="openDialog"
  >
    <slot>{{ data || t('common.notSet') }}</slot>
  </span>
</template>

<script lang="ts">
import { DefineComponent, defineComponent, PropType } from 'vue'
import { useDialog } from '@/functions/useDialog'
import { useI18n } from 'vue-i18n'
import { SetableDataCallbackPayload } from '@/utils/TableHelper'

export default defineComponent({
  name: 'BaseSetableData',
  props: {
    id: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: undefined
    },
    dialog: {
      type: Object as PropType<DefineComponent<unknown, unknown, any>>,
      required: true
    },
    dialogData: {
      type: Object,
      default: () => ({})
    },
    callback: {
      type: Function as PropType<(payload: SetableDataCallbackPayload<any>) => Promise<void>>,
      required: true
    },
    toast: {
      type: String,
      default: undefined
    }
  },
  emits: ['close', 'cancel', 'confirm'],
  setup (props) {
    const { t } = useI18n()
    const { setData } = useDialog()

    const openDialog = () => {
      if (props.disabled) return
      setData(props.dialog, { id: props.id, data: props.data, dialogData: { ...props.dialogData }, callback: props.callback, msg: props.toast })
    }

    return {
      t,
      openDialog
    }
  }
})
</script>
