import common from './zh-CN/common.json'
import error from './zh-CN/error.json'
import page from './zh-CN/page.json'
import player from './zh-CN/player.json'
import placeholder from './zh-CN/placeholder.json'
import layer from './zh-CN/layer.json'
import message from './zh-CN/message.json'
import method from './zh-CN/method.json'

export default {
  ...common,
  ...error,
  ...page,
  ...player,
  ...layer,
  ...placeholder,
  ...message,
  ...method
}
