<template>
  <div class="flex flex-wrap">
    <div
      v-for="item in rankOptions"
      :key="item.value"
      :class="{ active: modelValue.includes(item.value) }"
      class="relative w-16 h-16 px-10px m-5px border-1 border-solid border-#3B82F6 rounded-50% text-center cursor-pointer"
      @click="() => onClick(item.value)"
    >
      <img
        :src="item.icon"
        :alt="item.label"
        class="w-7.5"
      >
      <div class="text-3.5">
        {{ item.label }}
      </div>
      <div
        v-show="modelValue.includes(item.value)"
        class="absolute block w-4 h-4 rounded-50% right-0 bottom-0 bg-[var(--primary-color)] text-2.5 lh-3.5"
      >
        <i class="i-ri-check-fill color-white" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { PlayerRankType } from '@golden/gdk-agent-next'
import { getPlayerRankImage } from '@golden/shared'

export default defineComponent({
  name: 'BaseRankSelect',
  props: {
    modelValue: {
      type: Array as PropType<PlayerRankType[]>,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const { t } = useI18n()
    const rankOptions = [PlayerRankType.SILVER, PlayerRankType.GOLD, PlayerRankType.DIAMOND, PlayerRankType.CROWN, PlayerRankType.GLORY, PlayerRankType.KING, PlayerRankType.MASTER].map(item => ({ value: item, icon: getPlayerRankImage(item), label: t(`player.${item}`) }))

    const onClick = (value: PlayerRankType) => {
      const index = props.modelValue.findIndex(item => item === value)
      const newValue: PlayerRankType[] = [...props.modelValue]
      if (index === -1) {
        newValue.push(value)
      } else {
        newValue.splice(index, 1)
      }
      context.emit('update:modelValue', newValue)
    }

    return {
      rankOptions,
      onClick
    }
  }
})
</script>

<style lang="scss" scoped>
.active {
  @apply color-#2563eb border-#2563eb;
}
</style>
