<template>
  <base-filter-button
    :badge="badge"
    @reset="reset"
    @submit="submit"
    @after-hide="afterHide"
  >
    <div class="p-4 bg-white">
      <h4 class="m-0 mb-15px">
        {{ t('common.orderStatus') }}
      </h4>
      <base-status-select
        v-model="state.status"
        :options="statusOptions"
      />
    </div>
  </base-filter-button>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { TransactionStatus } from '@golden/gdk-agent-next'

export default defineComponent({
  name: 'TransactionFilterButton',
  props: {
    modelValue: {
      type: Object as PropType<{ status?: TransactionStatus[] }>,
      default: () => ({})
    }
  },
  emits: ['update:open', 'update:modelValue'],
  setup (props, context) {
    const { t } = useI18n()
    const state = reactive({ ...props.modelValue })

    const statusOptions = ref([
      {
        name: t('common.inProgress'),
        value: TransactionStatus.IN_PROGRESS
      },
      {
        name: t('common.success'),
        value: TransactionStatus.SUCCESSFUL
      },
      {
        name: t('common.failed'),
        value: TransactionStatus.FAIL
      }
    ])

    const submit = () => {
      context.emit('update:modelValue', state)
    }
    const reset = () => {
      context.emit('update:modelValue', { status: undefined })
    }

    const afterHide = () => {
      state.status = props.modelValue.status
    }

    const badge = computed(() => {
      return Object.values(props.modelValue).reduce<number>((accu, curr) => {
        if (curr === undefined) return accu
        if (Array.isArray(curr)) return accu + curr.length
        return accu + 1
      }, 0)
    })

    return {
      t,
      state,
      statusOptions,
      badge,
      reset,
      submit,
      afterHide
    }
  }
})
</script>
