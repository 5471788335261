<template>
  <div id="captcha" />
  <div class="login">
    <form
      class="flex flex-col items-center w-full px-12 md:w-58.333% pt-5rem md:pt-10rem"
      @submit.prevent="submit"
    >
      <div class="mb-12 flex-row-center">
        <img
          class="w-28px mr-7px"
          src="@/assets/images/icon.png"
          alt="logo"
        >
        <h2
          class="m-0 text-24px fw-700"
          :style="{ color: 'rgba(51, 81, 129, 1)' }"
        >
          {{ t('page.login') }}
        </h2>
      </div>
      <div class="w-full md:w-290px">
        <base-input
          v-model="state.account"
          :placeholder="t('common.account')"
        />
      </div>
      <div class="mt-4 w-full md:w-290px">
        <base-password
          v-model="state.password"
          :placeholder="t('common.password')"
          :feedback="false"
          :toggle-mask="true"
        />
      </div>
      <prime-button
        class="w-full md:w-290px mt-4"
        type="submit"
        :label="t('common.login')"
        :disabled="disabled || loading"
        :loading="loading"
      />
      <div class="flex-row-center mt-8 text-3.5">
        <span class="text-[var(--text-color-secondary)]">
          {{ t('common.cantLogin') }}
        </span>
        <prime-button
          href="#"
          class="p-button-text py-0 px-0.5 text-3.5"
          @click.prevent="openCustomer"
        >
          {{ t('common.contactCustomer') }}
        </prime-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useGDKFetch, useObservable } from '@golden/shared-vue'
import gdk from '@/gdk'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useCustomer } from '@/functions/useCustomer'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useQueryStore } from '@/stores/query'
import { useMediaQueryStore } from '@/stores/screen'
import { CaptchaServiceType, useCaptcha } from '@golden/captcha-vue'
import { urlHelper } from '@/utils/urlHelper'
import { useDialog } from '@/functions/useDialog'
export default defineComponent({
  name: 'LoginView',
  setup () {
    const { t } = useI18n()
    const router = useRouter()
    const toast = useToast()
    const { alert } = useDialog()
    const { openCustomer } = useCustomer()

    const state = reactive({
      account: '',
      password: ''
    })

    const rules = {
      account: { required },
      password: { required }
    }
    const v$ = useVuelidate(rules, state, { $autoDirty: true })
    const store = useQueryStore()
    const { isMobile } = useMediaQueryStore()
    const captchaId = (type: CaptchaServiceType, isH5: boolean) => {
      if (type === CaptchaServiceType.NET_EASY) {
        if (isH5) return import.meta.env.VITE_APP_NET_EASY_ID_H5
        return import.meta.env.VITE_APP_NET_EASY_ID
      } else if (type === CaptchaServiceType.BOTION) {
        if (isH5) return import.meta.env.VITE_APP_BOTION_ID_H5
        return import.meta.env.VITE_APP_BOTION_ID
      }
      return import.meta.env.VITE_APP_BOTION_ID
    }
    const captchaConfig = useObservable(gdk.config.get(), { captcha_enabled: false, captcha_type: CaptchaServiceType.BOTION })
    const { show: showCaptcha, destroy: destroyCaptcha, state: captchaState, reset: resetCaptcha, loading: captchaLoading } = useCaptcha(
      computed(() => captchaConfig.value.captcha_type),
      computed(() => captchaId(captchaConfig.value.captcha_type, isMobile)),
      {
        urlHelper
      },
      {
        success: () => login(),
        error: () => {
          void alert(t('common.networkTip')).then(() => { window.location.reload() })
        }
      }
    )

    const submit = () => {
      if (captchaConfig.value.captcha_enabled) {
        void showCaptcha()
        return
      }
      login()
    }

    const { login, disabled, loading: loginLoading } = useGDKFetch(
      'login',
      computed(() => !v$.value.$invalid),
      () => gdk.auth.login({
        ...state,
        captcha_type: captchaConfig.value.captcha_enabled ? captchaConfig.value.captcha_type : undefined,
        captcha: {
          ...captchaState.value,
          is_h5: isMobile
        }
      }),
      () => {
        store.$reset()
        void router.replace('/')
      },
      (error) => {
        void resetCaptcha()
        toast.add({ severity: 'error', detail: error.message, life: 1000 })
      }
    )
    const loading = computed(() => loginLoading.value || captchaLoading.value)

    onBeforeUnmount(() => {
      void destroyCaptcha()
    })

    return {
      t,
      state,
      submit,
      showCaptcha,
      openCustomer,
      disabled,
      loading
    }
  }
})
</script>

<style lang="scss" scoped>
  .login {
    @apply flex justify-end w-full h-full bg-no-repeat;
    background-image: url('@/assets/images/login.jpg');
    background-size: 100% auto;
    background-position: 0 332px;
    @media screen and (min-width: 768px) {
      background-position: 0;
      background-size: auto 100%;
    }
  }
</style>
