import { ErrorCode } from '@golden/gdk-agent-next'

export const SENTRY_IGNORE_CODES = [
  // auth error
  ErrorCode.UNAUTHENTICATED,
  // client error
  ErrorCode.CLIENT_NETWORK_ERROR,
  ErrorCode.CLIENT_TIMEOUT,
  // auto login failed
  ErrorCode.AUTO_LOGIN_FAIL
]
