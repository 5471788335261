<template>
  <layout-title>
    <template #start>
      <h3 class="title">
        {{ t('page.home') }}
      </h3>
    </template>
    <template #end>
      <prime-button
        v-for="button in iconNavs"
        :key="button.key"
        class="p-button-text p-button-rounded p-button-icon-only p-0"
        :class="{ 'important-pr-3': button.unread?.value }"
        @click="button.command"
      >
        <i
          :class="button.icon"
          class="p-button-icon text-5"
        />
        <i
          :class="button.activeIcon"
          class="p-button-icon text-5 hidden"
        />
        <prime-badge
          v-if="button.unread?.value"
          :value="button.unread.value > 99 ? '99+' : button.unread.value"
          severity="danger"
          class="absolute px-1 mb-3 ml-3 right-1"
        />
      </prime-button>
    </template>
  </layout-title>
  <layout-content
    :loading="loading"
    @reload="reload"
  >
    <div class="flex-row-between flex-wrap">
      <home-summary-card
        :raw-data="rawData"
        :loading="loading"
      />
      <div class="w-full md:w-[calc(33.3333%-0.5rem)] mt-4">
        <home-register-card
          :raw-data="rawData"
          :loading="loading"
        />
      </div>
      <div class="w-full md:w-[calc(33.3333%-0.5rem)] mt-4">
        <home-deposit-card
          :raw-data="rawData"
          :loading="loading"
        />
      </div>
      <div class="w-full md:w-[calc(33.3333%-0.5rem)] mt-4">
        <home-bet-card
          :raw-data="rawData"
          :loading="loading"
        />
      </div>
      <div class="w-full">
        <div class="flex flex-wrap flex-row-between items-stretch md:mx--0.375rem">
          <div class="w-full md:w-66.66% md:px-0.375rem mt-4 self-baseline">
            <home-platform-card
              :raw-data="rawData"
              :loading="loading"
              class="md:h-[calc(15vw)] md:min-h-50"
            />
          </div>
          <div class="w-full md:w-33.33% md:px-0.375rem mt-4 self-baseline">
            <leaderboard-card
              :raw-data="rawData"
              :loading="loading"
            />
          </div>
        </div>
      </div>
      <div class="w-full text-center md:text-right mt-4 text-3.5 color-[var(--text-color-secondary)]">
        {{ `${t('common.updatedAtWithColon')}${updatedAt}` }}
      </div>
    </div>
  </layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useGDKFetch } from '@golden/shared-vue'
import gdk from '@/gdk'
import { HomeRes } from '@golden/gdk-agent-next'
import { useI18n } from 'vue-i18n'
import { useCustomer } from '@/functions/useCustomer'
import { useRouter } from 'vue-router'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { useNav } from '@/functions/useNav'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { formatTaipeiTime, getTaipeiNowTime } from '@/utils/TimeHelper'

export default defineComponent({
  name: 'HomeView',
  setup () {
    const { t } = useI18n()
    const { openCustomer } = useCustomer()
    const router = useRouter()
    const { info } = storeToRefs(useAgentStore())

    const { nav: iconNavs } = useNav(computed(() => [
      {
        icon: 'i-ri-notification-2-line',
        activeIcon: 'i-ri-notification-2-fill',
        routeName: RouteNameType.ANNOUNCEMENT,
        command: () => { void router.push({ name: RouteNameType.ANNOUNCEMENT }) },
        unread: computed(() => info.value.unread_count)
      },
      {
        icon: 'i-ri-customer-service-line',
        activeIcon: 'i-ri-customer-service-fill',
        routeName: null,
        command: () => { openCustomer() }
      }
    ]))

    const defaultMonthData = () => ({
      commission: '0.0000',
      new_user_count: 0,
      deposit_user_count: 0,
      bet_user_count: 0,
      register_summary: { user_count: 0, deposit_user_count: 0, bet_user_count: 0, first_deposit: '0.0000', effective_bet: '0.0000' },
      deposit_summary: { user_count: [], count: [], cash: [] },
      bet_summary: { user_count: [], cash: [], effective_bet: [], revenue: [] },
      platform_summary: []
    })
    const rawData = ref<HomeRes>({ net_revenue: '0.0000', percentage: '0', last_month: defaultMonthData(), this_month: defaultMonthData(), updated_at: getTaipeiNowTime(), leaderboard: { positive: [], negative: [] } })
    const updatedAt = computed(() => formatTaipeiTime(rawData.value.updated_at, 'MM/dd HH:mm:ss'))
    const { reload, loading } = useGDKFetch('reload', computed(() => true), () => gdk.agent.getHomeData(), (res) => {
      rawData.value = res
    })
    reload()

    return {
      t,
      rawData,
      loading,
      updatedAt,
      iconNavs,
      reload
    }
  }
})
</script>

<style lang="scss" scoped>
.title {
  @apply fw-700 text-7 color-[var(--text-color)];
}
.p-button-text {
  &:hover .p-button-icon, &:active .p-button-icon {
    @apply first:hidden inline-block;
  }
}
</style>
