<template>
  <base-filter-button
    :badge="badge"
    @reset="reset"
    @submit="submit"
    @after-hide="afterHide"
  >
    <div class="mb-2 p-4 bg-white">
      <h4 class="m-0 mb-15px">
        {{ t('common.betStatus') }}
      </h4>
      <base-status-select
        v-model="state.transaction_status"
        :options="statusOptions"
      />
    </div>
    <div class="p-4 bg-white">
      <h4 class="m-0 mb-15px">
        {{ t('common.gamePlatform') }}
      </h4>
      <base-game-select v-model="state.games" />
    </div>
  </base-filter-button>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { BetStatus } from '@golden/gdk-agent-next'

export default defineComponent({
  name: 'BetFilterButton',
  props: {
    modelValue: {
      type: Object as PropType<{ transaction_status?: BetStatus[], games?: number[] }>,
      default: () => ({})
    }
  },
  emits: ['update:open', 'update:modelValue'],
  setup (props, context) {
    const { t } = useI18n()
    const state = reactive({ ...props.modelValue })

    const statusOptions = ref([
      {
        name: t('common.unsettled'),
        value: BetStatus.UNSETTLE
      },
      {
        name: t('common.settled'),
        value: BetStatus.SETTLE
      }
    ])

    const submit = () => {
      context.emit('update:modelValue', { transaction_status: state.transaction_status, games: state.games })
    }
    const reset = () => {
      context.emit('update:modelValue', { transaction_status: undefined, games: undefined })
    }

    const afterHide = () => {
      state.transaction_status = props.modelValue.transaction_status
      state.games = props.modelValue.games
    }

    const badge = computed(() => {
      return Object.values(props.modelValue).reduce<number>((accu, curr) => {
        if (curr === undefined) return accu
        if (Array.isArray(curr)) return accu + curr.length
        return accu + 1
      }, 0)
    })

    return {
      t,
      state,
      statusOptions,
      badge,
      reset,
      submit,
      afterHide
    }
  }
})
</script>
