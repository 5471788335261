<template>
  <div class="w-full">
    <div class="flex flex-row-between items-stretch md:mx--0.375rem">
      <div class="w-full md:w-50% md:px-0.375rem">
        <base-card class="summary-card">
          <div
            class="row rd-6px md-rd-0px shadow-[0px_4px_10px_rgba(0,_0,_0,_0.04)] md:shadow-none"
            :class="winLossBackground"
          >
            <div class="col">
              <div class="label">
                {{ t('common.inThisMonth', { item: t('common.netRevenue') }) }}
              </div>
              <base-skeleton
                :loading="loading"
                :options="{ height: '32px', width: '150px' }"
              >
                <span
                  class="value important-text-2rem"
                >
                  <base-cash
                    :cash="rawData.net_revenue"
                    format="currency"
                  />
                </span>
              </base-skeleton>
            </div>
          </div>
          <div class="row mt-7px md-mt-0px rd-t-6px md-rd-t-0px">
            <div class="col w-33.333%">
              <div class="label">
                {{ t('common.inThisMonth', { item: t('common.commission') }) }}
              </div>
              <base-skeleton
                :loading="loading"
                :options="{ height: '19px' }"
              >
                <span
                  class="value"
                >
                  <base-cash
                    :cash="rawData.this_month.commission"
                    format="currency"
                  />
                </span>
              </base-skeleton>
            </div>
            <div class="col w-33.333%">
              <div class="label">
                {{ t('common.dividedPercentage') }}
              </div>
              <base-skeleton
                :loading="loading"
                :options="{ height: '19px' }"
              >
                <span class="value">
                  {{ rawData.percentage + '%' }}
                </span>
              </base-skeleton>
            </div>
            <div class="col w-33.333%">
              <div class="label">
                {{ t('common.inLastMonth', { item: t('common.commission') }) }}
              </div>
              <base-skeleton
                :loading="loading"
                :options="{ height: '19px' }"
              >
                <span
                  class="value"
                >
                  <base-cash
                    :cash="rawData.last_month.commission"
                    format="currency"
                  />
                </span>
              </base-skeleton>
            </div>
          </div>
          <div class="row important-md:hidden rd-b-6px md-rd-b-0px">
            <div
              v-for="(item, index) in compareList"
              :key="index"
              class="col w-33.333%"
            >
              <div class="label">
                {{ item.title }}
              </div>
              <base-skeleton
                :loading="loading"
                :options="{ height: '19px' }"
              >
                <span class="value">
                  {{ item.value }}
                </span>
              </base-skeleton>
            </div>
          </div>
        </base-card>
      </div>
      <div
        v-for="(item, index) in compareList"
        :key="index"
        class="hidden md:block w-16.667% px-0.375rem"
      >
        <base-card class="h-full">
          <div class="flex-col-center h-full">
            <span class="title">{{ item.title }}</span>
            <span class="count">{{ item.value }}</span>
            <div
              class="badge"
              :style="{ background: item.bg }"
            >
              {{ t('common.compareTo', { item: t('common.lastMonth') }) }}
              <i
                :class="item.icon"
                :style="`color: var(--${item.color})`"
              />
            </div>
          </div>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { HomeRes } from '@golden/gdk-agent-next'
import { formatCurrencyBgColor, formatRoundDown } from '@/utils/format'

export default defineComponent({
  name: 'SummaryCard',
  props: {
    rawData: {
      type: Object as PropType<HomeRes>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()

    const compareList = computed(() => [
      {
        title: t('common.newMember'),
        key: 'new_user_count' as const
      },
      {
        title: t('common.numberOfPeople', { prefix: t('common.deposit') }),
        key: 'deposit_user_count' as const
      },
      {
        title: t('common.numberOfPeople', { prefix: t('common.bet') }),
        key: 'bet_user_count' as const
      }
    ].map((el) => {
      const compare = props.rawData.this_month[el.key] - props.rawData.last_month[el.key]
      return ({
        title: el.title,
        value: props.rawData.this_month[el.key],
        icon: compare > 0 ? 'i-ri-arrow-right-up-line' : compare < 0 ? 'i-ri-arrow-right-down-line' : 'i-ri-subtract-line',
        color: compare > 0 ? 'green-600' : compare < 0 ? 'red-600' : 'grey-400',
        bg: compare > 0 ? 'rgba(29, 167, 80, 0.07)' : compare < 0 ? 'rgba(217, 52, 43, 0.07)' : '#eeeeee'
      })
    }))

    const winLossBackground = computed(() => {
      const bgClass = formatRoundDown(props.rawData.net_revenue)
      return `important-${formatCurrencyBgColor(Number(bgClass))}`
    })

    return {
      t,
      compareList,
      winLossBackground
    }
  }
})
</script>

<style lang="scss" scoped>
.summary-card {
  @apply bg-transparent md:bg-#fff color-white;
}

.row {
  @apply flex bg-#fff important-md:bg-transparent;

  & + .row {
   @apply b-t-solid b-t b-t-#EEEEEE
  }
}
.label {
  @apply lh-normal color-#495057;
}

.value {
  @apply text-16px color-#495057;
  font-family: DIN Alternate, Roboto, -apple-system, BlinkMacSystemFont, Arial, Noto Sans, "Microsoft YaHei", sans-serif;
}
.col {
  @apply px-14px pt-10px pb-12px;

  & + .col {
    @apply b-l-solid b-l b-l-#EEEEEE
  }
}

.title {
  @apply color-[var(--text-color)];
}
.count {
  @apply text-28px lh-42px fw-700 color-[var(--text-color)] mb-15px;
  font-family: DIN Alternate, Roboto, -apple-system, BlinkMacSystemFont, Arial, Noto Sans, "Microsoft YaHei", sans-serif;
}
.badge {
  @apply px-3px h-20px w-84px lh-20px text-12px color-[var(--text-color)] b-rd-50px;

}
</style>
