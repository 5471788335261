import { Permissions } from '@golden/gdk-agent-next'

export enum RouteNameType {
  PREPARE = 'prepare',
  LOGIN = 'login',
  HOME = 'home',
  LOGOUT = 'logout',
  USERS = 'users',
  PLAYERS = 'players',
  PLAYER_DETAIL = 'player-detail',
  AGENTS = 'agents',
  AGENTS_DETAIL = 'agents-detail',
  AGENTS_ADD = 'agents-add',
  SUB_ACCOUNT = 'sub-account',
  SUB_ACCOUNT_DETAIL = 'sub-account-detail',
  SUB_ACCOUNT_ADD = 'sub-account-add',
  PROMOTE = 'promote',
  PROMOTE_URL = 'promote-url',
  PROMOTE_ACTIVITIES = 'promote-activities',
  REPORT = 'report',
  COMMISSION = 'commission',
  COMMISSION_DETAIL_COMMISSION = 'commission-detail',
  COMMISSION_DETAIL_FEEDBACK = 'feedback-detail',
  COMMISSION_DETAIL_PLATFORM_FEE = 'platform-fee-detail',
  COMMISSION_DETAIL_RISK_ADJUSTMENT = 'risk-adjustment-detail',
  COMMISSION_DETAIL_FINANCE_FEE = 'finance-fee-detail',
  BET = 'bet',
  BET_DETAIL = 'bet-detail',
  ACTIVITY = 'activity',
  ACTIVITY_DETAIL = 'activity-detail',
  TRANSACTION = 'transaction',
  TRANSACTION_DETAIL = 'transaction-detail',
  ME = 'me',
  ANNOUNCEMENT = 'announcement',
  ANNOUNCEMENT_GA = 'announcement-ga',
  ANNOUNCEMENT_AGENT = 'announcement-agent',
  SETTING = 'setting',
  AGENT_ANNOUNCEMENT = 'agent-announcement',
  AGENT_DEPOSIT = 'agent-deposit',
  AGENT_DEPOSIT_RECORD = 'agent-deposit-record',
  DEPOSIT = 'deposit',
  DEPOSIT_RECORD = 'deposit-record',
  TRANSFER = 'transfer',
  TRANSFER_RECORD = 'transfer-record',
  RECORD = 'record',
  LEADERBOARD = 'leaderboard',
  NOT_FOUND = '404',
  NO_NETWORK = 'no-network',
  LOAD_FAILED = 'load-failed'
}

export const routeNameMapPermissions: Record<RouteNameType, Permissions[]> = {
  [RouteNameType.PREPARE]: [],
  [RouteNameType.LOGIN]: [],
  [RouteNameType.HOME]: [Permissions.HOME],
  [RouteNameType.LOGOUT]: [],
  [RouteNameType.USERS]: [Permissions.USERS_PLAYERS, Permissions.USERS_AGENTS, Permissions.USERS_SUB_ACCOUNT],
  [RouteNameType.PLAYERS]: [Permissions.USERS_PLAYERS],
  [RouteNameType.PLAYER_DETAIL]: [Permissions.USERS_PLAYERS],
  [RouteNameType.AGENTS]: [Permissions.USERS_AGENTS],
  [RouteNameType.AGENTS_DETAIL]: [Permissions.USERS_AGENTS],
  [RouteNameType.AGENTS_ADD]: [Permissions.USERS_AGENTS],
  [RouteNameType.SUB_ACCOUNT]: [Permissions.USERS_SUB_ACCOUNT],
  [RouteNameType.SUB_ACCOUNT_DETAIL]: [Permissions.USERS_SUB_ACCOUNT],
  [RouteNameType.SUB_ACCOUNT_ADD]: [Permissions.USERS_SUB_ACCOUNT],
  [RouteNameType.PROMOTE]: [Permissions.PROMOTE_URL, Permissions.PROMOTE_ACTIVITIES],
  [RouteNameType.PROMOTE_URL]: [Permissions.PROMOTE_URL],
  [RouteNameType.PROMOTE_ACTIVITIES]: [Permissions.PROMOTE_ACTIVITIES],
  [RouteNameType.REPORT]: [Permissions.REPORT_COMMISSION, Permissions.REPORT_BET, Permissions.REPORT_TRANSACTION, Permissions.REPORT_ACTIVITY],
  [RouteNameType.COMMISSION]: [Permissions.REPORT_COMMISSION],
  [RouteNameType.COMMISSION_DETAIL_COMMISSION]: [Permissions.REPORT_COMMISSION],
  [RouteNameType.COMMISSION_DETAIL_FEEDBACK]: [Permissions.REPORT_COMMISSION],
  [RouteNameType.COMMISSION_DETAIL_PLATFORM_FEE]: [Permissions.REPORT_COMMISSION],
  [RouteNameType.COMMISSION_DETAIL_RISK_ADJUSTMENT]: [Permissions.REPORT_COMMISSION],
  [RouteNameType.COMMISSION_DETAIL_FINANCE_FEE]: [Permissions.REPORT_COMMISSION],
  [RouteNameType.BET]: [Permissions.REPORT_BET],
  [RouteNameType.BET_DETAIL]: [Permissions.REPORT_BET],
  [RouteNameType.ACTIVITY]: [Permissions.REPORT_ACTIVITY],
  [RouteNameType.ACTIVITY_DETAIL]: [Permissions.REPORT_ACTIVITY],
  [RouteNameType.TRANSACTION]: [Permissions.REPORT_TRANSACTION],
  [RouteNameType.TRANSACTION_DETAIL]: [Permissions.REPORT_TRANSACTION],
  [RouteNameType.ME]: [],
  [RouteNameType.ANNOUNCEMENT]: [Permissions.ANNOUNCEMENT],
  [RouteNameType.ANNOUNCEMENT_GA]: [Permissions.ANNOUNCEMENT],
  [RouteNameType.ANNOUNCEMENT_AGENT]: [Permissions.ANNOUNCEMENT],
  [RouteNameType.SETTING]: [Permissions.SETTING],
  [RouteNameType.AGENT_ANNOUNCEMENT]: [Permissions.AGENT_ANNOUNCEMENT],
  [RouteNameType.AGENT_DEPOSIT]: [Permissions.AGENT_DEPOSIT],
  [RouteNameType.AGENT_DEPOSIT_RECORD]: [Permissions.AGENT_DEPOSIT],
  [RouteNameType.DEPOSIT]: [Permissions.DEPOSIT],
  [RouteNameType.DEPOSIT_RECORD]: [Permissions.DEPOSIT],
  [RouteNameType.TRANSFER]: [Permissions.TRANSFER],
  [RouteNameType.TRANSFER_RECORD]: [Permissions.TRANSFER],
  [RouteNameType.RECORD]: [Permissions.RECORD],
  [RouteNameType.LEADERBOARD]: [Permissions.HOME],
  [RouteNameType.NOT_FOUND]: [],
  [RouteNameType.NO_NETWORK]: [],
  [RouteNameType.LOAD_FAILED]: []
}
