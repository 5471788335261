<template>
  <base-account-search-button
    v-model:visible="visible"
    :is-first-load="isFirstLoad && account.length > 1"
    :loading="loading && account.length > 1"
    :empty="list.length === 0"
    :finished="finished"
    @input="handleInput"
    @scroll="scroll"
    @after-hide="afterHide"
  >
    <template
      v-if="showToolBar && options.length"
      #toolbar
    >
      <div class="flex">
        <prime-button
          v-for="option in options"
          :key="option.type"
          :label="option.label"
          class="p-button-sm p-button-rounded mr-2 last:mr-0"
          :class="{ 'p-button-outlined': type !== option.type }"
          @click="() => type = option.type"
        />
      </div>
    </template>
    <div v-if="!isFirstLoad">
      <a
        v-for="item in list"
        :key="item.id"
        class="flex items-center cursor-pointer bg-white py-10px px-4 decoration-none mb-4px last:mb-0"
        @click="() => click(item)"
      >
        <prime-avatar
          shape="circle"
          :icon="item.layer ? undefined : 'i-ri-user-fill'"
          :image="item.layer ? getAvatars(Number(item.avatar ?? 0)) : undefined"
          class="mr-2 important-w-35px important-h-35px"
        />
        <div class="text-4 color-[var(--text-color)]">{{ item.account }}</div>
      </a>
    </div>
  </base-account-search-button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHistoryStore } from '@/stores/history'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { AccountSearchReq, AccountSearchRes, PaginationReq, AccountSearchType, AgentType } from '@golden/gdk-agent-next'
import { usePagination, defaultPaginationData } from '@golden/shared-vue'
import { of } from 'rxjs'
import gdk from '@/gdk'
import type { State } from '@/stores/history'
import { getAvatars } from '@/utils/getAvatar'

export default defineComponent({
  name: 'ReportAccountSearch',
  props: {
    history: {
      type: String as PropType<Exclude<keyof State, 'playerHistory'>>,
      required: true
    }
  },
  emits: ['click'],
  setup (props, context) {
    const { t } = useI18n()
    const account = ref('')
    const type = ref<AccountSearchType>(AccountSearchType.PLAYER)
    const visible = ref(false)
    const store = useHistoryStore()
    const { needSetFlag, ...history } = storeToRefs(store)

    const options = computed(() => [
      {
        label: t('common.player'),
        type: AccountSearchType.PLAYER
      },
      {
        label: t('common.agentLayer'),
        type: AccountSearchType.AGENT
      }
    ])

    const { me } = storeToRefs(useAgentStore())

    const list = computed(() => account.value.length > 1 ? accounts.value : (history[props.history].value[me?.value?.id as number] ?? []) as AccountSearchRes[])

    const { accounts, loading, finished, isFirstLoad, scroll, lastRes } = usePagination(
      'accounts',
      computed(() => ({ query_string: account.value, type: type.value })),
      (params) => params.query_string.length > 1
        ? gdk.common.getAccountSearch(params as AccountSearchReq & PaginationReq)
        : of(defaultPaginationData([] as AccountSearchRes[]))
    )

    const click = (payload: AccountSearchRes) => {
      needSetFlag.value = true
      store.addHistory(props.history, payload)
      visible.value = false
      context.emit('click', { ...payload, id_type: payload.layer ? AccountSearchType.AGENT : AccountSearchType.PLAYER })
    }

    const handleInput = (value: string) => { account.value = value }
    const total = computed(() => lastRes.value.total)
    const afterHide = () => {
      type.value = AccountSearchType.PLAYER
    }

    const showToolBar = computed(() => (me.value?.is_assistant ? me.value.parent_type : me.value?.type) !== AgentType.STAFF)

    return {
      t,
      account,
      options,
      list,
      total,
      loading,
      isFirstLoad,
      finished,
      type,
      scroll,
      visible,
      showToolBar,
      handleInput,
      click,
      afterHide,
      AccountSearchType,
      getAvatars
    }
  }
})
</script>
